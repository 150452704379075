import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Cookies from 'js-cookie';
import axios from "axios";
import { server } from "../../server";
import { toast } from 'react-toastify';
import { AiOutlineDelete } from 'react-icons/ai';
import { RxCross1 } from 'react-icons/rx';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function InvoiceStatusChangerModal({ invoice }) {
    const [open, setOpen] = useState(false);
    const { user, isAuthenticated } = useSelector((state) => state.user);
    const [desiredStatus, setDesiredStatus] = useState("");
    const navigate = useNavigate();

    if (!isAuthenticated) {
        return <></>
    }
    const handleClickOpen = (desiredStatus) => {
        setDesiredStatus(desiredStatus);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleStatusChange = () => {
        setOpen(false);
        try {
            const token = Cookies.get('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            let urlPath = "";
            if (invoice.paidBy == user._id) {
                urlPath = `/payer/${invoice._id}/change-status`;
            }
            if (invoice.createdBy == user._id) {
                urlPath = `/my-invoice/${invoice._id}/change-status`;
            }
            if (user.role == "Admin") {
                urlPath = `/admin/invoice/${invoice._id}/change-status`;
            }
            axios.put(`${server}/invoices${urlPath}`, { newStatus: desiredStatus }, config).then((res) => {
                toast.success(res.data.message);
                navigate(0);
            }).catch((err) => {
                toast.error(err.response.data.message);
            })

        } catch (err) {
            console.log("error", err);
        }
    };

    const RenderMessage = () => {
        switch (desiredStatus) {
            case "Draft":
                return (
                    <div>
                        This is an ADMINISTRATOR only operation. Usually invoices should never be changed after they are finalized.
                    </div>
                )

            case "Finalized":
                return (
                    <div>
                        Finalized invoices can no longer be edited. Please make sure the pay by date and all other details are correct before finalizing.
                    </div>
                );
            case "Canceled":
                return (
                    <div>
                        Canceled invoices cannot be reopened.
                    </div>
                );
            case "In Progress":
                return (
                    <div>
                        This is an ADMINISTRATOR only operation. This status is usually set automatically when the customer makes payment.
                    </div>
                );
            case "Completed (pending payout)":
                return (
                    <div>
                        Only mark an invoice as completed if the work has been completed to your satisfaction and you would like the funds to be released to the creator.
                    </div>
                );

            case "Disputed":
                return (
                    <div>
                        Please email <a href="mailto:support@connectsiimply.com" className='text-black'>support@connectsiimply.com</a> with details about why you are disputing this invoice.
                    </div>
                )

            case "Completed (paid out)":
                return (
                    <div>
                        This is an ADMINISTRATOR only operation. This should be set after the creator has been manually paid out.
                    </div>
                );
            default:
                return <></>;
        }
    }


    return (
        <div className='py-4'>
            <div className={`flex flex-wrap justify-center flex-col ${isAuthenticated && user.role !== "Admin" && "800px:flex-row 800px:space-y-0 800px:space-x-2"}   space-y-2`}>
                {/* if draft show the creator finalized and canceled */}
                {/* if finalized show cancel button to everyone with access */}
                {/* if in progress show payer the completed and disputedbutton */}
                {/* show admin all buttons */}
                {user.role == "Admin" ?
                    <button onClick={() => handleClickOpen("Draft")}
                        className="text-nowrap h-[35px] flex justify-center items-center px-4 border border-transparent text-md rounded-full text-black bg-[#FFFB00]"
                    >ADMIN: Transition to Draft</button> : <></>}
                {user.role == "Admin" || invoice.status == "Draft" && user._id == invoice.createdBy ?
                    <button onClick={() => handleClickOpen("Finalized")}
                        className="text-nowrap h-[35px] flex justify-center items-center px-4 border border-transparent text-md rounded-full text-black bg-[#FFFB00]"
                    >Transition to Finalized</button> : <></>}
                {user.role == "Admin" ?
                    <button onClick={() => handleClickOpen("In Progress")}
                        className="text-nowrap h-[35px] flex justify-center items-center px-4 border border-transparent text-md rounded-full text-black bg-[#FFFB00]"
                    >ADMIN: Transition to In Progress </button> : <></>}
                {user.role == "Admin" || ["Draft", "Finalized"].includes(invoice.status) && user._id == invoice.createdBy ?
                    <button onClick={() => handleClickOpen("Canceled")}
                        className="text-nowrap h-[35px] flex justify-center items-center px-4 border border-transparent text-md rounded-full text-black bg-[#FFFB00]"
                    >Transition to Canceled</button> : <></>}
                {user.role == "Admin" || invoice.status == "In Progress" && user._id == invoice.paidBy ?
                    <button onClick={() => handleClickOpen("Completed (pending payout)")}
                        className="text-nowrap h-[35px] flex justify-center items-center px-4 border border-transparent text-md rounded-full text-black bg-[#FFFB00]"
                    >Transition to Completed </button> : <></>}
                {user.role == "Admin" || invoice.status == "In Progress" && user._id == invoice.paidBy ?
                    <button onClick={() => handleClickOpen("Disputed")}
                        className="text-nowrap h-[35px] flex justify-center items-center px-4 border border-transparent text-md rounded-full text-black bg-[#FFFB00]"
                    >Transition to Disputed </button> : <></>}
                {user.role == "Admin" ?
                    <button onClick={() => handleClickOpen("Completed (paid out)")}
                        className="text-nowrap h-[35px] flex justify-center items-center px-4 border border-transparent text-md rounded-full text-black bg-[#FFFB00]"
                    >ADMIN: Transition to Completed Paid Out </button> : <></>}

            </div>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Changing status to <span className='text-black'>{desiredStatus}</span></DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" component={'span'}>
                        <div>
                            Are you sure you want to change the status of this invoice to <span className='text-black'>{desiredStatus}</span>?
                        </div>
                        <br />
                        <RenderMessage />
                        <br />
                        <div>
                            This cannot be undone.
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleStatusChange} color="secondary">
                        {desiredStatus}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default InvoiceStatusChangerModal;