import React, { useState } from "react";
import {
    AiFillHeart,
    AiFillStar,
    AiOutlineEye,
    AiOutlineHeart,
    AiOutlineStar,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { server } from "../../server";
import { useEffect } from "react";

import { toast } from "react-toastify";
import Ratings from "./../Reviews/Ratings.jsx";
import { BiBadgeCheck } from "react-icons/bi";
import { useParams, useOutletContext } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const PortfolioCard = ({ portfolio }) => {
    const [click, setClick] = useState(false);
    const [open, setOpen] = useState(false);
    const [userData, setUserData] = useState();
    const {portfolioId} = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const context = useOutletContext();
    useEffect(() => {
        if (context) {
            const [userProfile] = context;
            setUserData(userProfile);
        }
    }, []);

    return (
        <>
            <Link to={`/profile/${userData?._id}/portfolio/${portfolio._id}`} state={{ from: location.pathname }} className="mx-auto flex-1 w-full ">

                <div className=" w-full h-[370px] cursor-pointer relative shadow-sm 800px:border border-gray-200 dark:border-[#262627] dark:text-white border-[1px]" >
                    <img
                        src={`https://res.cloudinary.com/dljapfuhk/image/upload/c_fill,dpr_2.0,f_webp,g_auto,h_300,q_auto,w_600/v1/${portfolio.media[0]?.publicId}`}
                        alt=""
                        className="w-full object-cover 800px:rounded-t-lg"
                        style={{ height: "70%", minHeight: "30%" }}
                    />
                    <div style={{ paddingLeft: '3%', paddingRight: '3%', paddingTop: "5%" }}>
                        <h4 className="font-[500] 800px:height-[5lh]" >
                            {portfolio.title.length > 100 ? portfolio.title.slice(0, 100) + "..." : portfolio.title}
                        </h4>

                    </div>


                </div>
            </Link>

        </>

    );
};

export default PortfolioCard;
