import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Cookies from 'js-cookie';
import axios from "axios";
import { server } from "../../server";
import { toast } from 'react-toastify';
import { AiOutlineDelete } from 'react-icons/ai';
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../../redux/actions/user";
import { useNavigate } from "react-router-dom";
import { MdBlock } from 'react-icons/md';

function BlockModal({ userData }) {
    const { user, isAuthenticated } = useSelector((state) => state.user);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleBlock = async () => {
        if (!user) {
            navigate('/login');
        } else {
            try {
                // Retrieve the token from the cookies
                const token = Cookies.get('token');
                // Set up the authorization header with the token
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                };
                axios.put(`${server}/user/block-unblock/${userData._id}`, {}, config).then((res) => {
                    dispatch(loadUser());
                    handleClose();
                    if (res.data.user.blocked.includes(userData._id)) {
                        toast.success("You will no longer see this users forum posts or messages");
                    } else {
                        toast.success("You have unblocked this user");
                    }
                });
            } catch (err) {
                console.log("error", err);
            }
        }
    };

    return (
        <>
            {/* <Button
                    variant="text"
                    color="primary"
                    className='!text-xs '
                    onClick={handleClickOpen}>
                    {user?.blocked.includes(userData._id) ? "Unblock" : "Block"} {userData.name}
                </Button> */}

            <button className="text-[#000] dark:text-white text-center mt-2"
            onClick={handleClickOpen}>
                <MdBlock size={20} className="mx-auto text-black dark:text-[#FFFB00]" />
                {user?.blocked.includes(userData._id) ? "Unblock" : "Block"}
            </button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{user?.blocked.includes(userData._id) ? "Unblock" : "Block"} {userData.name}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to {user?.blocked.includes(userData._id) ? "Unblock" : "Block"}  {userData.name}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleBlock} color="secondary">
                        {user?.blocked.includes(userData._id) ? "Unblock" : "Block"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default BlockModal;