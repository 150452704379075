import axios from "axios";
import React, { useRef, useState } from "react";
import formatDistance from "date-fns/formatDistance";

import { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { AiOutlineHeart } from "react-icons/ai";
import { AiFillHeart } from "react-icons/ai";
import { server } from "../../server";
import Cookies from 'js-cookie';
import { AiOutlineMessage } from "react-icons/ai";
import { toast } from "react-toastify";
import Button from '@material-ui/core/Button';
import { CiLocationOn } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Markdown from 'react-markdown'
import { FaArrowLeft } from "react-icons/fa";
import Ratings from "../Reviews/Ratings";
import styles from "../../styles/styles";
import Tooltip from '@atlaskit/tooltip';
import { BiBadgeCheck } from "react-icons/bi";
import ShareModal from "../Services/ShareModal";
import AdminUnpublishModal from "../Services/AdminUnpublishModal";
import { Typography } from "@material-ui/core";
import { IoMdClose } from "react-icons/io";

const ServiceOfferingDetails = ({ serviceOfferingData, currentUrlTags, closeAdView }) => {
    const { user } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const location = useLocation();
    const mediaContainerRef = useRef(null);

    useEffect(() => {
        if (serviceOfferingData) {
            // send a get request to ensure that viewcount is updated
            axios.put(`${server}/services/increment-viewcount/${serviceOfferingData?._id}`);
        }
    }, [serviceOfferingData?._id]);


    useEffect(() => {
        let galleryWidget = null;
        if (window && !galleryWidget && serviceOfferingData?.media.length > 0) {
            // apply transformations to our media items
            serviceOfferingData?.media.forEach(item => {
                item.transformation = { "crop": "", "background": "#fff", "fetch_format": item.mediaType === "image" ? "webp" : "mp4" };
                item.thumbnailTransformation = { "crop": "fill", "dpr": "1.0", "fetch_format": "auto", "radius": 7 };

            });
            const loadingMedia = [{ publicId: "forum_media/1x1-00000000_dznnj4", transformation: { "crop": "fill", "background": "#fff", "dpr": "1.0", "fetch_format": "webp" }, thumbnailTransformation: { "crop": "fill", "dpr": "1.0", "fetch_format": "auto", "radius": 7 } }]
            galleryWidget = window.cloudinary.galleryWidget({
                container: mediaContainerRef.current,
                cloudName: 'dljapfuhk',
                mediaAssets: serviceOfferingData?.media ?? loadingMedia,
                carouselLocation: 'bottom',
                carouselStyle: 'thumbnails',
                preLoad: ["image", "video"],
                zoom: false,
                placeholderImage: false,
                analytics: false,
                thumbnailProps: {
                    selectedBorderPosition: 'bottom',
                    selectedBorderColor: '#FFFB00',
                    radius: 7
                },
                zoomProps: {
                    type: "popup",
                    steps: 0,
                },
                videoProps: {
                    controls: "all",
                    autoplay: false,
                    sound: true,
                },
                aspectRatio: "4:3",
            })
        }
        if (mediaContainerRef.current && galleryWidget && serviceOfferingData?.media.length > 0) {
            galleryWidget.render();
        }

        return () => {
            if (galleryWidget?.destroy) {
                galleryWidget.destroy();
            }
        };
    }, [serviceOfferingData]);


    return (
        <>
            {user?.role === "Admin" && (
                <div className="relative">
                    <AdminUnpublishModal offering={serviceOfferingData} />
                </div>
            )}

            <div className="relative p-2 dark:text-white">
                <h1 className="mx-auto max-w-[90%] text-3xl font-400 text-center p-1 text-black dark:text-[#FFFB00]">
                    {serviceOfferingData?.title ?? "Loading"}
                </h1>
                <ShareModal offering={serviceOfferingData} />
                <IoMdClose size={25} className="ml-2 my-2 cursor-pointer absolute top-0 left-0 dark:text-[#FFFB00]" onClick={closeAdView} />

                <div className="shadow-sm border border-gray-200 dark:border-[#837c7c] mx-[10%] p-3 mt-2 rounded-xl">
                    <Link to={`/profile/${serviceOfferingData?.account._id}/services`} className="flex flex-col">
                        <div className="relative">
                            <img
                                src={`https://res.cloudinary.com/dljapfuhk/image/upload/ar_1.0,c_thumb,g_auto,f_auto/r_max/v1703309315/${serviceOfferingData?.account.avatar?.public_id}.jpg`}
                                className="w-[40%] min-w-[40%] rounded-full mx-auto" alt=""
                            />
                            {serviceOfferingData?.account?.online && (
                                <div className="absolute bg-green-500 w-20 font-light text-gray-500 text-center rounded-lg bottom-[-5px] left-[50%] transform -translate-x-1/2 flex items-center justify-center">
                                    online
                                </div>
                            )}
                        </div>

                        <div className="mt-2 mx-auto flex items-center">
                            <h5 className="text-3xl font-bold dark:text-white">{serviceOfferingData?.account.name ?? "Loading..."}</h5>
                            {serviceOfferingData?.account.isPremium && (
                                <BiBadgeCheck size="27" className="dark:text-[#FFFB00]" />
                            )}
                        </div>
                        {serviceOfferingData?.account.title && (
                            <div className="mx-auto text-sm text-black dark:text-[#FFFB00] font-medium my-[-5px]">
                                {serviceOfferingData?.account.title}
                            </div>
                        )}
                        {serviceOfferingData?.account.publicLocation && (
                            <div className="text-sm mx-auto flex items-center">
                                <CiLocationOn /> {serviceOfferingData?.account.publicLocation ?? "Planet Earth"}
                            </div>
                        )}
                    </Link>

                    <Button
                        variant="contained"
                        color="primary"
                        className="!mx-auto !block !my-4 !rounded-full"
                        onClick={() => navigate(`/chat?with=${serviceOfferingData?.account?._id}`)}
                    >
                        <Typography style={{ textTransform: 'none' }}>Message to start project</Typography>
                    </Button>
                    <div className="text-sm text-center">
                        <h1 className="text-l text-center">Starting From: ${serviceOfferingData?.startingPrice ?? "000"}</h1>
                        Languages spoken: {serviceOfferingData?.languagesSpoken}<br />
                        Open to collaboration: {serviceOfferingData?.openToCollaboration ? "yes" : "No"}
                    </div>
                </div>
            </div>
            <Button
                variant="contained"
                color="primary"
                className="!mx-auto w-1/2 !block !my-4 !rounded-full"

                onClick={() => navigate(`/profile/${serviceOfferingData?.account._id}/services`)}
            >
                <Typography style={{ textTransform: 'none' }}>View Profile</Typography>
            </Button>

            {serviceOfferingData?.tags.length > 0 && (
                <div className="p-2">
                    <h2 className="text-lg py-1 mb-2 bg-[#FFFB00] text-black rounded-full text-center inline-block px-4">Skills</h2>
                    <div className="flex flex-wrap gap-y-2">
                        {serviceOfferingData?.tags.map((tag, index) => (
                            <Tooltip key={index} content={tag.description}>
                                <span className="inline-block rounded-full px-3 py-1 text-sm mr-2 bg-[#FFFB00]">
                                    {tag.name}
                                </span>
                            </Tooltip>
                        ))}
                    </div>
                </div>
            )}

            {serviceOfferingData?.description.length > 0 && (
                <div className="p-2">
                    <h2 className="text-lg py-1 mb-2 bg-[#FFFB00] text-black rounded-full text-center inline-block px-4">About service</h2>
                    <Markdown className="prose text-black dark:prose-invert dark:text-white pb-2">{serviceOfferingData?.description}</Markdown>
                </div>
            )}

            {serviceOfferingData?.media.length > 0 && (
                <div className="p-2">
                    <h2 className="text-lg py-1 mb-2 bg-[#FFFB00] text-black rounded-full text-center inline-block px-4">Work</h2>
                    <div ref={mediaContainerRef} style={{ minHeight: "350px", display: "block" }}></div>
                </div>
            )}

            {(serviceOfferingData?.account.linkedinURL ||
                serviceOfferingData?.account.twitterHandle ||
                serviceOfferingData?.account.instagramHandle ||
                serviceOfferingData?.account.pinterestHandle) && (
                    <div className="p-2">
                        <h2 className="text-lg py-1 mb-2 bg-[#FFFB00] text-black rounded-full text-center inline-block px-4">Links</h2>
                        <div className="flex space-x-4">
                            {serviceOfferingData?.account.linkedinURL && (
                                <a href={serviceOfferingData?.account.linkedinURL} target="_blank" rel="noreferrer">
                                    <img src={require("../../images/icons8-linkedin-96.png")} alt="LinkedIn" className="w-10 h-10 rounded-full" />
                                </a>
                            )}
                            {serviceOfferingData?.account.twitterHandle && (
                                <a href={`https://twitter.com/${serviceOfferingData?.account.twitterHandle}`} target="_blank" rel="noreferrer">
                                    <img src={require("../../images/icons8-x-logo-96.png")} alt="Twitter" className="w-10 h-10 rounded-full" />
                                </a>
                            )}
                            {serviceOfferingData?.account.instagramHandle && (
                                <a href={`https://instagram.com/${serviceOfferingData?.account.instagramHandle}`} target="_blank" rel="noreferrer">
                                    <img src={require("../../images/icons8-instagram-logo-94.png")} alt="Instagram" className="w-10 h-10 rounded-full" />
                                </a>
                            )}
                            {serviceOfferingData?.account.pinterestHandle && (
                                <a href={`https://pinterest.com/${serviceOfferingData?.account.pinterestHandle}`} target="_blank" rel="noreferrer">
                                    <img src={require("../../images/icons8-pinterest-logo-96.png")} alt="Pinterest" className="w-10 h-10 rounded-full" />
                                </a>
                            )}
                        </div>
                    </div>
                )}
        </>
    );
};

export default ServiceOfferingDetails;
