import React from "react";
import { useState } from "react";
import RightSidebar from "../../components/Social/RightSideBar";
import LoginPage from "../../pages/Login";
import Header from "../../components/Layout/Header";
import { useSelector } from "react-redux";
import { useParam, useSearchParams } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { ChannelFilters, ChannelOptions, ChannelSort, User } from 'stream-chat';
import { useEffect } from "react";
import { useCallback } from "react";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup'
import {
    Attachment,
    Channel,
    ChannelHeader,
    ChannelList,
    Chat,
    LoadingIndicator,
    MessageInput,
    MessageList,
    Thread,
    Window,
} from 'stream-chat-react';

import 'stream-chat-react/dist/css/v2/index.css';

import { IoMdArrowRoundBack } from "react-icons/io";
import './Chat.css';


const ChatPage = () => {
    const { user, chatClient } = useSelector((state) => state.user);
    const [searchParams] = useSearchParams();
    const [isMobileNavVisible, setIsMobileNavVisible] = useState(true);
    const [channelId, setChannelId] = useState(null);
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (chatClient) {
            setFilters({ type: 'messaging', members: { $in: [chatClient.user.id] } });

            if (searchParams.get("with") && searchParams.get("with") !== chatClient.user.id) {
                const newChannel = chatClient.channel('messaging', { members: [chatClient.user.id, searchParams.get("with")] });
                newChannel.watch().then((res) => {
                    setChannelId(res.channel.id);
                    setLoading(false);
                    setIsMobileNavVisible(false);
                }).catch((err) => {
                    setLoading(false);
                });
            } else {
                // user is not chatting with anyone
                setLoading(false);
            }
        }
    }, [searchParams.get("with"), chatClient?.user?.id]);

    useEffect(() => {
        document.querySelectorAll('.footer-class').forEach(el => {
            el.style.display = "none";
        });
        // document.body.style.overflow = 'hidden';
        const mobileChannelList = document.querySelector('.mobile-channel-list');
        const chatView = document.querySelector('.mobile-chat-view');
        // console.log("trasitioning");
        if (isMobileNavVisible && mobileChannelList) {
            mobileChannelList.classList.add('show');
            setTimeout(() => {
                chatView.classList.add('hide');
            }, 500);

        } else if (!isMobileNavVisible && mobileChannelList) {
            mobileChannelList.classList.remove('show');
            chatView.classList.remove('hide');
        }
        return () => {
            document.querySelectorAll('.footer-class').forEach(el => {
                el.style.display = "block";
            });
            // document.body.style.overflow = 'auto';
        }
    }, [isMobileNavVisible]);

    const MenuIcon = ({ title }) => {
        return (
            <>
                <span onClick={() => setIsMobileNavVisible(true)}>
                    <IoMdArrowRoundBack color="#000" size="30" />
                </span>
            </>
        );
    };
    if (!chatClient || !filters || loading || !user) {
        return <div className="col-span-3 bg-[#fafafa]" style={{ height: "70vh", }}>
            Loading chat please wait....
        </div>


    }

    const sort = { last_message_at: -1 };

    const options = {
        limit: 100,
    };

    const CustomAttachment = (props) => {
        const { attachments } = props;
        const [attachment] = (attachments || [])
        if (attachment?.type === 'invoice') {
            return (
                <div className="str-chat__message-text">
                    Click to view invoice:
                    <a href={attachment.link} rel="noreferrer" target="_blank" className="pl-1">
                        {attachment.title}
                    </a>
                </div>
            );
        }

        return <Attachment {...props} />;
    };

    return (
        <>
            <Chat client={chatClient} theme={document.documentElement.classList.contains('dark') ? "str-chat__theme-dark" : ""}>
                <div onClick={() => setIsMobileNavVisible(false)} className="col-span-1 rounded mobile-channel-list show" style={{ height: "70vh" }}>
                    <ChannelList customActiveChannel={channelId} filters={filters} sort={sort} options={options} />
                </div>
                <div className="col-span-2 mobile-chat-view hide" style={{ height: "70vh" }}>

                    <Channel Attachment={CustomAttachment}>
                        <Window>
                            <ChannelHeader MenuIcon={MenuIcon} />
                            <MessageList messageActions={['edit', 'delete', 'quote', 'react']} />
                            <MessageInput />
                        </Window>
                    </Channel>
                </div>
            </Chat >

        </>
    );


};


export default ChatPage;
