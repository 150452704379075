import React from "react";
import { Link } from 'react-router-dom';
import { FaInstagram, FaLinkedin, FaTiktok } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { useState, useEffect } from 'react';

const Footer = () => {
      const [logoSrc, setLogoSrc] = useState(
        document.documentElement.classList.contains('dark')
          
          ? require("../../images/white-logo.png")
          : require("../../images/dark-logo.png")
      );
    
      useEffect(() => {
        const updateLogo = () => {
          setLogoSrc(
            document.documentElement.classList.contains('dark')
              ? require("../../images/white-logo.png")
              : require("../../images/dark-logo.png")
          );
        };
    
        const observer = new MutationObserver(updateLogo);
    
        observer.observe(document.documentElement, {
          attributes: true,
          attributeFilter: ['class'],
        });
    
        // Initial check in case the initial state was set before the class was applied
        updateLogo();
    
        return () => {
          observer.disconnect();
        };
      }, []);
  return (
    <footer className="w-full footer-class bg-black dark:bg-white text-white dark:text-black">
      <Link
        to="/"
        className="800px:ml-[45px] block pt-4"
      >
        <img
          src={logoSrc}

          alt="Logo"
          height={45}
          className="max-h-[45px] mx-auto 800px:mx-0"
        />
      </Link>
      <div className="mx-auto text-center 800px:text-left 1000px:w-10/12 w-[95%]">
        <div className="grid grid-cols-1 800px:grid-cols-4 text-sm py-8 align-middle space-y-4 800px:space-y-0">
          {/* Column 1 */}
          <div>
            <h3 className="font-bold text-xl mb-2">Made for Creatives</h3>
            <ul>
              <li><Link to="#">Post Jobs</Link></li>
              <li><Link to="/explore">Post Creative Content</Link></li>
              <li><Link to="/explore">Follow Freelancers</Link></li>
              <li><Link to="/manage-portfolio/">Upload Portfolio</Link></li>
              <li><Link to="/explore">Engage in Content</Link></li>
            </ul>
          </div>

          {/* Column 2 */}
          <div>
            <h3 className="font-bold text-xl mb-2">Contact</h3>
            <ul>
              <li><a href='mailto:contact@connectsiimply.com'>contact@connectsiimply.com</a></li>
              <li><a href='mailto:support@connectsiimply.com'>support@connectsiimply.com</a></li>
            </ul>
          </div>

          {/* Column 3 */}
          <div>
            <h3 className="font-bold text-xl mb-2">Find Talents</h3>
            <ul >
              <li><Link to="/services/Graphic_Designer">Graphic Design</Link></li>
              <li><Link to="/services/UI%2FUX_Designer">UI/UX Design</Link></li>
              <li><Link to="/services/Photography">Photography</Link></li>
              <li><Link to="/services/Videography">Videography</Link></li>
              <li><Link to="/services/Social_Media">Social Media Manager</Link></li>
            </ul>
          </div>

          {/* Column 4 */}
          <div>
            <h3 className="font-bold text-xl mb-2">Socials</h3>
            <ul className="flex flex-col space-y-2 items-center 800px:items-start">
              <li>
                <a href="https://instagram.com/connectsiimply" target="_blank" className="flex items-center">
                  <FaInstagram className="mr-2" /> Instagram
                </a>
              </li>
              <li>
                <a href="https://x.com/ConnectSiimply" target="_blank" className="flex items-center">
                  <FaXTwitter className="mr-2" /> X
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/connectsiimply/" target="_blank" className="flex items-center">
                  <FaLinkedin className="mr-2" /> LinkedIn
                </a>
              </li>
              <li>
                <a href="https://www.tiktok.com/@connectsiimply" target="_blank" className="flex items-center">
                  <FaTiktok className="mr-2" /> TikTok
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="text-left pb-4 pl-4 text-sm">
        <div>Created with love by the ConnectSiimply team.</div>
        <div>©2025 ConnectSiimply All rights reserved.</div>
      </div>
    </footer>
  );
};

export default Footer;