import React, { useState } from "react";
import ProfileSideBar from "../../components/Profile/ProfileSidebar";
import { useSelector } from "react-redux";
import LoginPage from "../Login";
import styles from "../../styles/styles";
import axios from "axios";
import { server } from "../../server";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const ProfileChangePassword = () => {
    const { isAuthenticated } = useSelector((state) => state.user);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const passwordChangeHandler = async (e) => {
        e.preventDefault();
        // Retrieve the token from the cookies
        const token = Cookies.get('token');
        // Set up the authorization header with the token
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };
        await axios
            .put(
                `${server}/user/update-user-password`,
                { oldPassword, newPassword, confirmPassword },
                config
            )
            .then((res) => {
                toast.success(res.data.message);
                setOldPassword("");
                setNewPassword("");
                setConfirmPassword("");
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });
    };

    if (!isAuthenticated) {
        return <LoginPage />
    }
    return (
        <div>
            <div className={`w-[90%] mx-auto 800px:flex py-10`}>
                <ProfileSideBar active={"change-password"} />
                <div className="w-full py-4 800px:mx-4 shadow rounded-lg px-10 my-4 800px:my-0">
                    <div className="800px:w-[50%] mx-auto">
                        <h1 className="block text-[25px] text-center font-[600] text-[#000000ba] dark:text-[#d9d9d9] pb-2">
                            Change Password
                        </h1>
                        <form
                            aria-required
                            onSubmit={passwordChangeHandler}
                            className="flex flex-col items-center space-y-6"
                        >
                            <div className="w-full">
                                <label
                                    htmlFor="password"
                                    className="block text-sm font-medium text-gray-700 dark:text-[#FFFB00]">
                                    Current Password
                                </label>
                                <div className="mt-1 relative">
                                    <input
                                        type={oldPasswordVisible ? "text" : "password"}
                                        name="password"
                                        autoComplete="current-password"
                                        required
                                        value={oldPassword}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#FFFB00]"
                                    />
                                    {oldPasswordVisible ? (
                                        <AiOutlineEye
                                            className="absolute right-2 top-2 cursor-pointer"
                                            size={25}
                                            onClick={() => setOldPasswordVisible(false)}
                                            color="#000"
                                        />
                                    ) : (
                                        <AiOutlineEyeInvisible
                                            className="absolute right-2 top-2 cursor-pointer"
                                            size={25}
                                            onClick={() => setOldPasswordVisible(true)}
                                            color="#000"
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="w-full">
                                <label
                                    htmlFor="setNewPassword"
                                    className="block text-sm font-medium text-gray-700 dark:text-[#FFFB00]"
                                >
                                    New Password
                                </label>
                                <div className="mt-1 relative">
                                    <input
                                        type={newPasswordVisible ? "text" : "password"}
                                        name="newPassword"
                                        autoComplete="new-password"
                                        required
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#FFFB00]"
                                    />
                                    {newPasswordVisible ? (
                                        <AiOutlineEye
                                            className="absolute right-2 top-2 cursor-pointer"
                                            size={25}
                                            onClick={() => setNewPasswordVisible(false)}
                                            color="#000"
                                        />
                                    ) : (
                                        <AiOutlineEyeInvisible
                                            className="absolute right-2 top-2 cursor-pointer"
                                            size={25}
                                            onClick={() => setNewPasswordVisible(true)}
                                            color="#000"
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="w-full">
                                <label
                                    htmlFor="setConfirmPassword"
                                    className="block text-sm font-medium text-gray-700 dark:text-[#FFFB00]"
                                >
                                    Re-enter New Password
                                </label>
                                <div className="mt-1 relative">
                                    <input
                                        type={confirmPasswordVisible ? "text" : "password"}
                                        name="setConfirmPassword"
                                        autoComplete="new-password"
                                        required
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#FFFB00]"
                                    />
                                    {confirmPasswordVisible ? (
                                        <AiOutlineEye
                                            className="absolute right-2 top-2 cursor-pointer"
                                            size={25}
                                            onClick={() => setConfirmPasswordVisible(false)}
                                            color="#000"
                                        />
                                    ) : (
                                        <AiOutlineEyeInvisible
                                            className="absolute right-2 top-2 cursor-pointer"
                                            size={25}
                                            onClick={() => setConfirmPasswordVisible(true)}
                                            color="#000"
                                        />
                                    )}
                                </div>
                            </div>


                            <button
                                type="submit"
                                className="w-full h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-xl rounded-full text-black bg-[#FFFB00]"
                            >
                                Change Password
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileChangePassword;
