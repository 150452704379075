import React, { useState, useEffect, useCallback } from 'react';
import Post from './Post';
import axios from 'axios';
import { server } from '../../server';
import { useParams, useOutletContext } from "react-router-dom";
import ExploreTweets from './ExplorePosts';

const SocialProfilePosts = () => {
    const [userProfile, postId, setPostId, posts, setPosts] = useOutletContext();
    const { id } = useParams();
    return (
        <div className='min-h-[50vh]'>
            <ExploreTweets setPostId={setPostId} postId={postId} posts={posts} setPosts={setPosts} url={`${server}/posts/all/${id}`} />
            {posts?.length === 0 && <div className='text-center py-4 mb-[50vh]  dark:text-[#cecaca]'>This user has not yet posted.</div>}
        </div>)
};

export default SocialProfilePosts;