import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Cookies from 'js-cookie';
import axios from "axios";
import { server } from "../../server";
import { toast } from 'react-toastify';
import { AiOutlineDelete } from 'react-icons/ai';
import { RxCross1 } from 'react-icons/rx';
import CompactProfile from '../Social/CompactProfile';
import { useNavigate } from 'react-router-dom';

function InvoiceShareModal({ invoice }) {
    const [open, setOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const navigate = useNavigate();


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleshare = (userId) => {
        try {
            const token = Cookies.get('token');

            // Set up the authorization header with the token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            axios.put(`${server}/invoices/my-invoice/${invoice._id}/share`, { userId }, config).then((res) => {
                toast.success(res.data.message);
                navigate(0);
                // setOpen(false);

            }).catch((err) => {
                toast.error(err.response.data.message);
            })

        } catch (err) {
            console.log("error", err);
        }
    };

    useEffect(() => {
        axios.get(`${server}/search/users/${searchQuery}?maxResults=5`).then((res) => {
            // console.log(res.data);
            setSearchResults(res.data.users);
        });
    }, [searchQuery]);

    const minWidth = window.innerWidth / 3;

    return (
        <>
            <button onClick={handleClickOpen}
                   className=" mx-auto mt-4 flex items-center justify-center h-[35px] px-4 border border-transparent text-md rounded-full text-black bg-[#FFFB00]">
            Share invoice with additional users</button>

            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title"
                
                className="dark:bg-[#262627] dark:text-[#d9d9d9]" >Invoice sharing</DialogTitle>
                <DialogContent
                className="dark:bg-[#262627]"
                 >
                    <DialogContentText id="alert-dialog-description" className='!text-black' component={'span'} >
                        <input
                            type="text"
                            name="search"
                            value={searchQuery}
                            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search for a name..."
                        />
                        <div className="mt-2 space-y-2">
                            {searchResults.map((user) => (
                                <div className='flex flex-row justify-between items-center' key={user._id}>
                                    <CompactProfile key={user._id} userObject={user} showFollowButton={false} />
                                    {!invoice.sharedWith.includes(user._id) && invoice.createdBy !== user._id ?
                                        <button
                                            className="w-[100px] h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-lg rounded-full text-black bg-[#FFFB00]"
                                            onClick={() => handleshare(user._id)}>
                                            Share
                                        </button>
                                        : null}
                                </div>
                            ))}
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                className='dark:bg-[#262627]'>
                    <Button onClick={handleClose} 
                    color={document.documentElement.classList.contains('dark') ? "primary" : "secondary"}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default InvoiceShareModal;