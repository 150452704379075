import { useEffect } from "react";
import axios from "axios";
import { server } from "../../server";
import { useState } from "react";
import { useOutletContext } from "react-router";
import PortfolioCard from "./PortfolioCard";
import { useLoaderData } from "react-router-dom";
import { useNavigation } from "react-router-dom";

const PortfolioCardList = () => {
    const portfolioData = useLoaderData().portfolios;
    if (portfolioData?.length > 0) {
        return (
            <div className="min-h-[50vh]">
                <div className={`grid ${ (portfolioData.length > 1 ? "800px:grid-cols-2" : "")} grid-cols-1 mb-4 gap-[20px]`}>
                    {portfolioData && portfolioData.length > 0 && portfolioData.map((portfolio, index) => <PortfolioCard portfolio={portfolio} key={portfolio._id}  />)}
                </div>
            </div>
        )
    } else {
        return (
            <div className="min-h-[50vh]">
                <div className="text-center py-4 dark:text-[#cecaca]">
                    This user has not yet created a portfolio.
                </div>
            </div>
        )
    }
};
export default PortfolioCardList;
