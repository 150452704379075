import React, { useState, useEffect, useCallback, memo } from "react";
import RightSidebar from '../../components/Social/RightSideBar';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Post from "../../components/Social/Post";
import { server } from "../../server";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { CiCalendar, CiLocationOn } from "react-icons/ci";
import { BiBadgeCheck } from "react-icons/bi";
import {
  loadUser,
} from "../../redux/actions/user";
import { Button } from "@material-ui/core";
import LoginPage from "../Login";
import { toast } from "react-toastify";
import BlockModal from "../../components/Social/BlockModal";
import { MdBlock } from "react-icons/md";
import { AiOutlineCamera } from "react-icons/ai";
import { Outlet } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useLoaderData } from "react-router";
import { FaLink } from "react-icons/fa";
import {GoLink} from "react-icons/go"

const sanitizeHtml = require('sanitize-html');
const SocialProfile = () => {
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const [userProfile, setUserProfile] = useState(null);
  const [bio, setBio] = useState(null);
  const [postId, setPostId] = useState(null);
  const [posts, setPosts] = useState(null);
  const [bioTruncated, setBioTruncated] = useState(true);
  const [bioTruncationNeeded, setBioTruncationNeeded] = useState(false);
  const navigate = useNavigate();
  const userProfileLoader = useLoaderData().user;
  const { id } = useParams();
  const dispatch = useDispatch();

  const getFullDomain = () => {
    try {
      const url = userProfile?.portfolioLink;
      if (!url) {
        throw new Error('URL is not defined');
      }
  
      const hostname = new URL(url).hostname;
      const parts = hostname.split('.').slice(-2);
  
      // Return the domain without subdomains
      return parts.join('.');
    } catch (error) {
      // console.error('Invalid URL:', error);
      return '';
    }
  };
  const creationDate = () => {
  
    const date = new Date(userProfile?.createdAt);
  
    // Extract the date components
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth(); // Get the zero-indexed month
    const day = String(date.getUTCDate()).padStart(2, '0');
  
    // Array of month names
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
  
    // Get the month's name
    const monthName = monthNames[month];
  
    return `Joined ${monthName} ${year}`;
  };
  

    // Utility function to sanitize input, convert URLs to anchor tags, and newlines to <br> tags
    const convertBioToHTML = (text) => {

      // Sanitize the text to remove any unwanted HTML
      const cleanText = sanitizeHtml(text, {
        allowedTags: [],
        allowedAttributes: {}
      });
      let truncatedText = cleanText;
      if (cleanText.length > 300 && bioTruncated) {
        setBioTruncated(true);
        setBioTruncationNeeded(true);
        truncatedText = cleanText.substring(0, 300) + '... ';
      } else {
        setBioTruncated(false);
      }

      // Convert HTTP/HTTPS links, emails, and phone numbers to clickable links
      const linkConvertedText = truncatedText.replace(/(?:https?:\/\/)?([\w-]+\.[a-zA-Z]{2,}(?:\/[\w./?%&=-]*)?)|(\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b)|(\b\d{3}[-.]?\d{3}[-.]?\d{4}\b)/gi, (match, urlWithoutProtocol, email, phone) => {
        if (urlWithoutProtocol) {
          // Prepend 'http://' if the protocol is not present in the original URL match.
          const urlWithProtocol = match.startsWith('http://') || match.startsWith('https://') ? match : `http://${urlWithoutProtocol}`;
          return `<a href="${urlWithProtocol}" style="font-weight:bold">${urlWithoutProtocol}</a>`;
        } else if (email) {
          // Email matched
          return `<a href="mailto:${email}" style="font-weight:bold">${email}</a>`;
        } else if (phone) {
          // Phone matched, assuming US format for example
          return `<a href="tel:${phone}" style="font-weight:bold">${phone}</a>`;
        }
      });
      // Convert newlines to <br> tags
      return linkConvertedText.replace(/\n/g, '<br>');

    }
    useEffect(() => {
      setUserProfile(userProfileLoader);
      setBio(convertBioToHTML(userProfileLoader.bio));
      setBioTruncated(true);
      setBioTruncationNeeded(false);
    }, [id]);
    useEffect(() => {
     
      setBio(convertBioToHTML(userProfileLoader.bio));

    },[bioTruncated]);
    const handleFollow = async () => {
      if (!user) {
        navigate('/login');
      } else {
        try {
          // Retrieve the token from the cookies
          const token = Cookies.get('token');
          // Set up the authorization header with the token
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          };
          const follow = await axios.put(`${server}/user/follow-unfollow/${id}`, {}, config);
          if (follow.data.user.following.includes(id)) {
            setUserProfile({ ...userProfile, followerCount: userProfile.followerCount + 1, followers: [...userProfile.followers, user._id] });
            // toast.success("You are now following this user");

          } else {
            setUserProfile({ ...userProfile, followerCount: userProfile.followerCount - 1, followers: userProfile.followers.filter(follower => follower !== user._id) });
            // toast.success("You have unfollowed this user");

          }
          dispatch(loadUser());
        } catch (err) {
          console.log("error", err);
        }
      }
    };

    const updateBanner = async (e) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          // Retrieve the token from the cookies
          const token = Cookies.get('token');
          // Set up the authorization header with the token
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          };
          axios
            .put(
              `${server}/user/update-banner`,
              { banner: reader.result },
              config
            )
            .then((response) => {
              setUserProfile({ ...userProfile, banner: { public_id: response.data.banner_public_id } });
              toast.success("Banner updated successfully!");
            })
            .catch((error) => {
              toast.error(error);
            });
        }
      };

      reader.readAsDataURL(e.target.files[0]);
    };

    if (!isAuthenticated) {
      return <LoginPage />
    }
    return (
      <>
        <div className="800px:py-6 grid grid-cols-1 md:grid-cols-4">
          <div></div>
          <div className="col-span-2 max-w-[800px]">
            <div className="800px:mt-6  relative border-solid 800px:rounded-lg shadow">
              {/* Banner Image */}
              <img
                src={`https://res.cloudinary.com/dljapfuhk/image/upload/ar_4:1,c_lpad,b_gen_fill/v1703309315/${userProfile?.banner?.public_id}.jpg`}
                className="w-full h-[200px] object-cover 800px:rounded-t-lg"
                onError={(e) => {
                  // Check if the fallback URL has already been tried
                  if (!e.currentTarget.dataset.triedFallback) {
                    // Set the flag
                    e.currentTarget.dataset.triedFallback = 'true';
                    // Change the image source to the fallback URL
                    e.currentTarget.src = `https://res.cloudinary.com/dljapfuhk/image/upload/ar_4:1,c_lpad/v1703309315/${userProfile?.banner?.public_id}.jpg`;
                  }
                }}
                alt="User Banner"
              />

              {/* Camera Icon */}
              {/* If the user is viewing their own profile, show the camera icon to update the banner image */}
              {user?._id === id && (
                <button className="text-black text-center absolute top-4 right-4 cursor-pointer"
                >
                  <input
                    type="file"
                    id="image"
                    className="hidden"
                    onChange={updateBanner}
                  />
                  <label htmlFor="image">
                    <AiOutlineCamera color="white" size={30} className="" />
                  </label>
                </button>)}

              <div className="absolute w-full px-4 mt-[-55px] ">
                {/* Avatar Image */}
                <span className="flex flex-row justify-between">
                  <img
                    src={`https://res.cloudinary.com/dljapfuhk/image/upload/b_white,ar_1.0,c_thumb,g_auto,f_auto/r_max/v1703309315/${userProfile?.avatar?.public_id}.jpg`}
                    className="w-[150px] mt-[-35px] h-[150px] rounded-full mr-[10px]"
                    alt="User Avatar"
                    hidden={!userProfile}
                  />
                  {userProfile && user._id !== userProfile?._id && (
                    <span className="justify-end flex-col flex">
                      <BlockModal userData={userProfile} />
                    </span>
                  )}
                </span>

                <div className="flex flex-col">
                  <div className="flex items-center">
                    <h1 className="text-2xl 800px:text-3xl font-bold line-clamp-2 dark:text-white">{userProfile?.name}</h1>
                    {userProfile?.isPremium && (<BiBadgeCheck className="ml-1 text-black dark:text-[#FFFB00]" size="30" />)}
                  </div>
                  {userProfile?.title ?
                    (<div className="text-sm font-medium my-[-5px] dark:text-[#FFFB00]">
                      {userProfile?.title || ' '}
                    </div>) : ' '}
                </div>

              </div>
              <div className="mx-[45px] mt-[130px] dark:text-white">
                <div style={{ whiteSpace: "pre-line" }} >
                  <span dangerouslySetInnerHTML={{ __html: bio }} />
                  {bioTruncated && bioTruncationNeeded && (
                    <button onClick={() => setBioTruncated(false)} className="font-bold">
                      Show More
                    </button>
                  ) || (bioTruncationNeeded
                    && <button onClick={() => setBioTruncated(true)} className="font-bold">
                      Show Less
                    </button>)}
                </div>

              </div>
              <div className="flex flex-row justify-evenly 800px:mx-[45px] mt-[15px]">
                <div className="800px:text-base text-sm flex items-center dark:text-[#cecaca]">
                  {userProfile?.publicLocation ? (<><CiLocationOn className="dark:text-[#FFFB00]" size={30} /> {userProfile.publicLocation}</>) : ' '}
                </div>
                {userProfile?.createdAt ? (
                <div className="800px:text-base text-sm flex items-center dark:text-[#cecaca]">
                <CiCalendar className="dark:text-[#FFFB00]" size={30} /> {creationDate()}
                </div>
                ):''}
                {getFullDomain().length>0 ? (
                <div className="800px:text-base text-sm flex items-center dark:text-[#cecaca]">
                 <GoLink className="dark:text-[#FFFB00]" size={25} /> <a href={userProfile.portfolioLink} target="_blank"> <span className="ml-1">{getFullDomain()}</span></a> 
                </div>
              ) : ' '}
              </div>
              <div className="flex flex-row space-x-2 max-w-[600px] 800px:px-[45px] px-[25px] mx-auto mt-[15px]">
                <NavLink
                  replace={true}
                  preventScrollReset={true}
                  className="w-full h-[40px] flex justify-center items-center py-2 px-2 border border-transparent rounded-full bg-[#FFFB00] SocialProfile_FollowerButton__NavLink"
                  to={`/profile/${userProfile?._id}/followers`} >
                  {userProfile?.followerCount || 0} Followers
                </NavLink>

                {user?._id === id ? (
                  <button
                    className="w-full h-[40px] flex justify-center items-center py-2 px-2 border border-transparent rounded-full bg-[#FFFB00]"
                    onClick={() => navigate('/profile')}>
                    My Account
                  </button>

                ) : !user.blocked?.includes(id) ? (

                  <button
                    className="w-full h-[40px] flex justify-center items-center py-2 px-2 border border-transparent rounded-full bg-[#FFFB00]"
                    onClick={handleFollow}>
                    {user?.following?.includes(id) ? ("Unfollow") : ("Follow"
                    )}
                  </button>


                ) : null}
                {(user?._id !== id && !user.blocked?.includes(id)) ? (
                  <button
                    onClick={() => navigate(`/chat?with=${id}`)}
                    className="w-full h-[40px] flex justify-center items-center py-2 px-2 border border-transparent rounded-full bg-[#FFFB00]"
                  >
                    Message
                  </button>
                ) : null}
              </div>
              <div className="flex flex-row mx-[45px] mt-[15px]">
                <NavLink
                  replace={true}
                  preventScrollReset={true}
                  to={`/profile/${id}/posts`}
                  className="w-full h-[40px] flex justify-center items-center py-2 px-2 dark:text-[#cecaca] SocialProfile__NavLink"
                >
                  Posts
                </NavLink>
                <NavLink
                  replace={true}
                  preventScrollReset={true}
                  to={`/profile/${id}/services`}
                  className="w-full h-[40px] flex justify-center items-center py-2 px-2 dark:text-[#cecaca] SocialProfile__NavLink"
                >
                  Services
                </NavLink>
                <NavLink
                  replace={true}
                  preventScrollReset={true}
                  to={`/profile/${id}/Portfolio`}
                  className="w-full h-[40px] flex justify-center items-center py-2 px-2 dark:text-[#cecaca] SocialProfile__NavLink"
                >
                  Portfolio
                </NavLink>
              </div>
            </div>
            <div className="mt-6">
              <Outlet context={[userProfile, postId, setPostId, posts, setPosts]} />
            </div>
          </div>

          <div className="800px:px-3">
            <RightSidebar postId={postId} setPostId={setPostId} posts={posts} setPosts={setPosts} />
          </div>
        </div>
      </>
    );
  };

  export default SocialProfile;
