import { Link } from "react-router-dom";

const AuthHeader = () => {
    return (
        <div className="text-center dark:bg-black">
            <Link to="/" className="inline-block mt-8">
                <img
                    src={document.documentElement.classList.contains('dark') ? require("../../images/dark-logo.png") : require("../../images/white-logo.png")}
                    alt="Logo"
                    className=" max-h-[40px] 800px:max-h-[60px]"
                />
            </Link>
        </div>
    );
};

export default AuthHeader;
