import React from "react";
import LoginPage from "../Login";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";
import ServiceOfferingCard from "../../components/Services/ServiceOfferingCard";
import { useSearchParams } from "react-router-dom";
import ServiceOfferingDetails from "../../components/Services/ServiceOfferingDetails";
import { useLoaderData } from "react-router";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { components } from 'react-select';
import Tooltip from '@atlaskit/tooltip';
import { useNavigate } from "react-router-dom";
import ServiceOfferingReviews from "../../components/Reviews/OfferingReviews";
import { Slider } from '@material-ui/core'
import { IoIosArrowDropdown } from "react-icons/io";
import { IoIosArrowDropup } from "react-icons/io";
import { Backdrop, Button } from "@material-ui/core";
import CompactProfile from "../../components/Social/CompactProfile";

const MultiValue = (props) => {
  return (
    <Tooltip content={props.data.toolTip}>
      <components.MultiValue {...props} />
    </Tooltip>
  );
};
const animatedComponents = makeAnimated({ MultiValue: MultiValue });

const ServicesHome = () => {
  let { tags } = useParams();
  tags = tags ? encodeURIComponent(tags).replace("%2C", ",") : "";
  let [searchParams, setSearchParams] = useSearchParams();
  const serviceData = useLoaderData();
  const [selectedTags, setSelectedTags] = useState([]);
  const [allTags, setAllTags] = useState(null);
  const [location, setLocation] = useState(searchParams.get("location") ?? "");
  const [language, setLanguage] = useState(searchParams.get("language") ?? "");
  const [hourlyRate, setHourlyRate] = useState([searchParams.get("minPrice") ?? 10, searchParams.get("maxPrice") ?? 500]);
  const [mobileFilterViewable, setMobileFilterViewable] = useState(false);
  const [adOpen, setAdOpen] = useState(false);
  const navigate = useNavigate();
  const [suggestedUsers, setSuggestedUsers] = useState([]);
  const { isAuthenticated } = useSelector((state) => state.user);

  const fetchData = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${Cookies.get('token')}`,
      };
      const suggestedUsers = await axios.get(`${server}/user/suggest-users`, { headers });
      setSuggestedUsers(suggestedUsers.data.suggested);
    } catch (err) {
      console.log("error", err);
    }
  };


  useEffect(() => {
    if (searchParams.get("location")) {
      setLocation(searchParams.get("location"));
    }
    if (searchParams.get("language")) {
      setLanguage(searchParams.get("language"));
    }
    if (searchParams.get("minPrice") && searchParams.get("maxPrice")) {
      setHourlyRate([searchParams.get("minPrice"), searchParams.get("maxPrice")]);
    }
    fetchData();

  }, [])
  useEffect(() => {
    if (searchParams.get("id")) {
      setAdOpen(true);
      if (searchParams.get("source")) {
        setSearchParams((prev) => {
          prev.delete("source");
          if (language) {
            prev.set("language", language);
          }
          if (location) {
            prev.set("location", location);
          }
          if (hourlyRate[0] > 10) {
            prev.set("minPrice", hourlyRate[0]);
          }
          if (hourlyRate[1] < 500) {
            prev.set("maxPrice", hourlyRate[1]);
          }
          return prev;
        }, { replace: true });
      }

    }
  }, [searchParams.get("id"), searchParams.get("source")]);

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }

  const debouncedUpdate = useCallback(
    debounce((name, value) => {
      if (searchParams.get(name) || value) {
        setSearchParams((prev) => {
          prev.set(name, value);
          prev.delete("id");
          return prev;
        }, { replace: true });
      }
    }, 300),
    []
  );

  // Debounced function to update searchParams
  const updateSearchParams = useCallback(
    debounce((newHourlyRate) => {
      setSearchParams((prev) => {
        if (newHourlyRate[0] > 10) {
          prev.set("minPrice", newHourlyRate[0]);
        } else {
          prev.delete("minPrice");
        }
        if (newHourlyRate[1] < 500) {
          prev.set("maxPrice", newHourlyRate[1]);
        } else {
          prev.delete("maxPrice");
        }
        if (newHourlyRate.length > 1 && (newHourlyRate[0] > 10 || newHourlyRate[1] < 500)) {
          prev.delete("id");
        }
        return prev;
      }, { replace: true });
    }, 300),
    []
  );


  useEffect(() => {

    setSelectedTags(serviceData?.selectedTags?.map((tag) => {
      return { value: tag._id, label: tag.name, toolTip: tag.description };
    }));
    setAllTags(serviceData?.allTags?.map((tag) => {
      return { value: tag._id, label: tag.name, toolTip: tag.description };
    }));
  }, [tags]);


  const visibleOfferings = serviceData?.serviceOfferings?.filter(offering => !offering.hidden);

  return (
    <div className="flex 800px:flex-row flex-col-reverse justify-center 800px:space-x-2 800px:py-6 800px:mt-6">
      <div className={` block space-y-2 max-w-[600px] w-full`}>
        {!visibleOfferings || visibleOfferings.length == 0 ?
          <div className="text-center mt-4">
            Not Results found
          </div> : ""
        }

        {visibleOfferings?.map((offering, index) => {
          return <ServiceOfferingCard offering={offering} key={offering._id + " card"} urlParams={searchParams} preventScrollReset={true} currentUrlTags={tags} openAdView={() => setAdOpen(true)} />;
        })}
      </div>
      <div className={`800px:w-[50%] w-full max-w-[600px]`}>
        <div className={`p-4 m-2 rounded-lg bg-[#f8f8f8] dark:bg-[#262627] border-2 border-[#f2f2f2] dark:border-[#837c7c]`}>
          <div className="flex flex-row 800px:flex-col items-center 800px:items-start">
            <IoIosArrowDropdown size={35} className={`mr-2 800px:hidden dark:text-white ${mobileFilterViewable ? "hidden" : ""}`} onClick={() => setMobileFilterViewable((oldState) => !oldState)} />
            <IoIosArrowDropup size={35} className={`mr-2 800px:hidden dark:text-white ${!mobileFilterViewable ? "hidden" : ""}`} onClick={() => setMobileFilterViewable((oldState) => !oldState)} />
            <h2 className="text-xl font-medium py-4 800px:block hidden dark:text-[#cecaca]">Search for tags</h2>
            <Select
              key={searchParams.get("id")}
              className="w-full "
              closeMenuOnSelect={true}
              components={animatedComponents}
              isMulti
              options={allTags}
              value={selectedTags}
              isClearable={true}
              onChange={(tags) => {
                let searchParamsWithoutId = new URLSearchParams(searchParams.toString());
                searchParamsWithoutId.delete("id");
                navigate(
                  `/services/${tags.map((tag) => encodeURIComponent(tag.label.replaceAll(" ", "_"))).join(",")}/?${searchParamsWithoutId.toString()}`
                )
              }}
              placeholder={"Search for tags"}
              noOptionsMessage={() => "No tags are available"}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles
                }),
              }}
              classNames={{
                multiValue: (state) =>
                  '!bg-[#FFFB00] !rounded-full',
                multiValueRemove: (state) =>
                  '!rounded-r-full ',
                control: (state) =>
                  '!shadow-none !rounded-full'
              }}
              theme={(theme) => (
                {
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#FFFB00",
                    primary25: '#FFFECC',
                    primary50: '#FFFECC',
                  },
                })}
            />
          </div>
          <span className={`${mobileFilterViewable ? "block" : "hidden"} 800px:block`}>
            <h2 className="text-xl font-medium py-4 dark:text-[#cecaca]">Hourly Rate</h2>
            <div className="mx-4">
              <Slider
                getAriaLabel={() => 'HourlyRate'}
                value={hourlyRate}
                onChange={(event, newValue) => {setHourlyRate(newValue);updateSearchParams(newValue)}}
                valueLabelDisplay="off"
                disableSwap
                min={10}
                max={500}
                color={document.documentElement.classList.contains('dark') ? "primary" : "secondary"}
              />
              <div className="flex justify-between flex-row">
                <span className="inline-block rounded-full px-5 py-2 text-md  bg-[#FFFB00] ">${hourlyRate[0]}</span>
                <span className="inline-block rounded-full px-5 py-2 text-md bg-[#FFFB00] ">${hourlyRate[1] === 500 ? "500+" : hourlyRate[1]}</span>
              </div>
            </div>


            <h2 className="text-xl font-medium py-4 dark:text-[#cecaca]">Search Location</h2>
            <input
              placeholder="Toronto, Ontario"
              type="text"
              value={location}
              onChange={(e) => {setLocation(e.target.value);  debouncedUpdate("location", e.target.value)}}

              className="w-full appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#FFFB00] placeholder-bold"
            />
            <h2 className="text-xl font-medium py-4 dark:text-[#cecaca]">Search Languages</h2>
            <input
              placeholder="English"
              type="text"
              value={language}
              onChange={(e) =>{ setLanguage(e.target.value);debouncedUpdate("language", e.target.value)}}
              className="w-full appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#FFFB00] placeholder-bold"
            />
            {isAuthenticated &&
              <>
                <h2 className="text-xl font-medium py-4 800px:block hidden dark:text-[#cecaca]">Follow Recommendations</h2>
                <span className="space-y-2 800px:block hidden">
                  {suggestedUsers.map((user) => (
                    <CompactProfile key={user._id} userObject={user} />
                  ))}
                </span>
              </>}
          </span>
          {searchParams.get("id") && adOpen &&
            <div className="fixed h-full bg-white dark:bg-[#262627] border border-gray-200 dark:border-[#837c7c] overflow-y-scroll top-0 z-30 right-0 800px:w-6/12 w-11/12" key={searchParams.get("id")}>

              <ServiceOfferingDetails key={searchParams.get("id") + "details"} serviceOfferingData={serviceData?.serviceOfferings?.find(service => service._id == searchParams.get("id"))} currentUrlTags={tags} closeAdView={() => { setAdOpen(false) }} />

              <ServiceOfferingReviews key={searchParams.get("id") + " review"} serviceOfferingId={searchParams.get("id")} type="services" />

            </div>
          }
        </div>
      </div>
      <Backdrop open={adOpen && searchParams.get("id")} onClick={() => setAdOpen(false)} style={{ zIndex: 10 }} invisible={true} />
    </div>

  );
};
export default ServicesHome;
