import React from "react";
import CommentList from "./CommentList";
import { AiOutlineClose } from "react-icons/ai";

const RightSidebar = ({postId, setPostId, posts, setPosts}) => {
  if(!postId){
    return null;
  }
  return (
    <div className="bg-white dark:bg-[#262627] 800px:sticky fixed p-2 800px:h-[50vh] h-[80%] w-full  rounded-sm flex flex-col top-[20%]" style={{zIndex:"3"}} >
      <div></div>
      <AiOutlineClose className="cursor-pointer absolute text-black dark:text-[#FFFB00]" size="20" onClick={() => { setPostId(null) }} />

      <h2 className="font-bold text-center border-b-[1px] border-solid pb-[8px] dark:text-[#d9d9d9]">Comments</h2>
      <CommentList postId={postId} posts={posts} setPosts={setPosts}/>
    </div>
  );
};

export default RightSidebar;