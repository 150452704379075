import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RxCross1 } from "react-icons/rx";
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import { RxHamburgerMenu } from "react-icons/rx";
import { IoIosPeople } from "react-icons/io";
import { MdOutlineWork } from "react-icons/md";
import { MdOutlineEmojiPeople } from "react-icons/md";
import { FaDollarSign, FaUserGear } from "react-icons/fa6";
import { Backdrop, Button } from "@material-ui/core";
import { IoChatbubbleEllipsesOutline, IoBookmark, IoNotifications } from "react-icons/io5";
import { AiOutlineBell } from "react-icons/ai";
import { IoNotificationsOutline } from "react-icons/io5";
import { AiOutlineSearch } from "react-icons/ai";
import axios from "axios";
import { server } from "../../server";
import CompactProfile from "../Social/CompactProfile";
import ServiceOfferingCard from "../../components/Services/ServiceOfferingCard";
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { FiPlus } from "react-icons/fi";
import { BiSolidMessage } from "react-icons/bi";
import { Typography } from "@material-ui/core";
import { FaSearch } from "react-icons/fa";

const Header = () => {
  const { isAuthenticated, user, loading, unreadCount, unreadPlatformNotificationsCount } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchVisible, setSearchVisible] = useState(false);
  const location = useLocation();
  useEffect(() => {
    // Retrieve the token from the cookies
    const token = Cookies.get('token');

    // Set up the authorization header with the token
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    };

    axios.get(`${server}/search/everything/${searchQuery}?maxResults=4`, config).then((res) => {
      setSearchResults(res.data);
    });
  }, [searchQuery]);

  const toggleDrawer = (newOpen) => () => {
    setSearchVisible(false);
    setOpen(newOpen);
  };
  useEffect(() => {
    // Add or remove class to body based on the 'open' state
    if (open) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");

    }
  }, [open]);

  const menuItems = [
    { to: "/search", icon: AiOutlineSearch, text: "Search" },
    { to: "/services/", icon: IoIosPeople, text: "Hire Talent" },
    { to: "/posts", icon: MdOutlineEmojiPeople, text: "My Feed" },
    { to: "/explore", icon: MdOutlineEmojiPeople, text: "Explore" },
    { to: "/premium", icon: FaDollarSign, text: "Premium" },
    { to: "/profile", icon: FaUserGear, text: "Account Settings" },
  ];

  const renderMenuItem = (item) => (
    <div className="flex items-center py-4 text-l ml-8" onClick={() => item.onClick}>
      <item.icon size={30} />
      <span className={`pl-3`}>{item.text}</span>
    </div>
  );

    const [logoSrc, setLogoSrc] = useState(
      document.documentElement.classList.contains('dark')
        ? require("../../images/dark-logo.png")
        : require("../../images/white-logo.png")
    );
  
    useEffect(() => {
      const updateLogo = () => {
        setLogoSrc(
          document.documentElement.classList.contains('dark')
            ? require("../../images/dark-logo.png")
            : require("../../images/white-logo.png")
        );
      };
  
      const observer = new MutationObserver(updateLogo);
  
      observer.observe(document.documentElement, {
        attributes: true,
        attributeFilter: ['class'],
      });
  
      // Initial check in case the initial state was set before the class was applied
      updateLogo();
  
      return () => {
        observer.disconnect();
      };
    }, []);

  const DrawerList = (
    <Box
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      className="relative" // Make the Box a positioning context
    >
      <List>
        {menuItems.map((item, index) => {
          return (
            <Link key={index} to={item.to} target={item.target}>
              {renderMenuItem(item)}
            </Link>
          );
        })}
        <div className="cursor-pointer 800px:hidden text-center py-4">
          {isAuthenticated ? (
            <Link
              to={`/profile/${user?._id}/posts`}
              className="flex justify-center flex-col text-center"
            >
              <img
                src={`https://res.cloudinary.com/dljapfuhk/image/upload/ar_1.0,c_thumb,g_auto,f_auto/r_max/v1703309315/${user.avatar?.public_id}.jpg`}
                className="h-[55px] w-[55px] rounded-full mx-auto"
                alt=""
                hidden={!user}
              />
              <span>{user?.name}</span>
            </Link>
          ) : loading ? (
            <></>
          ) : (
            <>
              <Link
                to="/login"
                onClick={toggleDrawer(false)}
                className="mr-2 py-2 px-4 border border-solid border-2 border-black text-xl rounded-full font-bold" >
                Login
              </Link>
              <Link
                to="/sign-up"
                className=" py-2 px-6 border border-solid border-2 border-[#FFFB00] text-xl rounded-full font-bold bg-[#FFFB00] text-black"
                onClick={toggleDrawer(false)}>
                Join
              </Link>
            </>
          )}
        </div>
      </List>
    </Box>
  );

  return (
    <>
      <div className="h-[70px]">
      </div>
      <div
        className={`fixed flex top-0 left-0 z-20 transition justify-between w-full bg-white dark:bg-black h-[70px]`}
      >
        <div
          className={`w-full mx-auto relative flex justify-between items-center`}
        >
          <div className="flex flex-row items-center">
            <div
              className="hidden 800px:block flex items-center justify-center rounded-full bg-[#4A4A4C] mr-8 1400px:ml-20 1300px:ml-16 ml-3.5"
            >
              <FiPlus
                size={35}
                className={`select-none cursor-pointer m-1`}
                onClick={() => open ? setOpen(false) : setOpen(true)}
                color="#FFFB00"
              />
            </div>
            <div className=" flex flex-row items-center">
              <Link to="/" className="800px:ml-0 ml-[15px]" onClick={toggleDrawer(false)}>
                <img
                  src={logoSrc}
                  alt="Logo"
                  height={35}
                  className="800px:max-h-[35px] max-h-[30px]"
                />
              </Link>
            </div>
          </div>
          <div className={`absolute left-[50%] translate-x-[-50%] ${["/", "/search", "/search/"].includes(location.pathname) ? "hidden" : ""}`}>
            <div className="1400px:flex flex-col align-stretch items-start items-center w-[600px] h-[70px] hidden">
              <input
                placeholder="Search ConnectSiimply"
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onFocus={() => setSearchVisible(true)}
                className="mt-[15px] dark:text-white dark:bg-[#262627] appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#FFFB00]"
              />
              <button type="submit" className='absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-300'>
                <FaSearch className="" size={20} />
              </button>
              {searchResults.users?.length > 0 && searchVisible &&
                <div className="w-full bg-white dark:bg-[#262627] shadow rounded-lg pb-2 mt-1">
                  <h1 className="block text-[25px] text-center font-[600] text-[#000000ba] dark:text-[#cecaca] pb-2">
                    Users
                  </h1>
                  {
                    searchResults.users.map((userObject) => {
                      if (isAuthenticated && user.blocked?.includes(userObject._id)) return null;
                      return (
                        <div className="p-2 forum-post" key={userObject._id} onClick={toggleDrawer(false)}>
                          <CompactProfile userId={userObject._id} userObject={userObject} showFollowButton={false} />
                        </div>
                      );
                    })

                  }
                  <Link to={`/search?query=${searchQuery}`}
                    onClick={() => { setSearchVisible(false); setSearchQuery("") }}
                    className="w-1/2 mx-auto h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-xl rounded-full bg-[#FFFB00]"
                  >
                    See More Results
                  </Link>
                </div>
              }
              {searchResults.services?.length > 0 && searchVisible &&
                <div className={`mt-1 pb-2 w-full bg-white dark:bg-[#262627] shadow rounded-lg p-4 space-y-2`}>
                  <h1 className="block text-[25px] text-center font-[600] text-[#000000ba] dark:text-[#cecaca] pb-2 800px:col-span-2">
                    Services
                  </h1>
                  {
                    searchResults.services.map((serviceObject) => {
                      return (
                        <div key={serviceObject._id} onClick={toggleDrawer(false)}>
                          <ServiceOfferingCard offering={serviceObject} />
                        </div>
                      );
                    })
                  }
                  <Link to={`/search?query=${searchQuery}`}
                    onClick={() => { setSearchVisible(false); setSearchQuery("") }}
                    className="800px:col-span-2 w-1/2 mx-auto h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-xl rounded-full bg-[#FFFB00]"
                  >
                    See More Results
                  </Link>
                </div>
              }
            </div>
          </div>
          <div className="flex justify-end items-center" >
            <div
              className="block 800px:hidden mr-2 flex items-center justify-center rounded-full bg-[#4A4A4C]"
            >
              <FiPlus
                size={35}
                className={`select-none cursor-pointer m-1`}
                onClick={() => open ? setOpen(false) : setOpen(true)}
                color="#FFFB00"
              />
            </div>

            <Link to="/notifications" className={`800px:mr-5 mr-2 flex ${!isAuthenticated ? "hidden" : ""}`} onClick={toggleDrawer(false)}>
              <div
                className="relative items-center justify-center rounded-full bg-[#4A4A4C]"
              >
                <IoNotifications color="#FFFB00" size="27" className="m-2" />
                {unreadPlatformNotificationsCount > 0 && (<span class="absolute bottom-0 right-0 w-4 h-4 bg-[#FF0909] rounded-full"></span>)}
              </div>
            </Link>
            <Link to="/chat" className={`800px:mr-5 flex ${!isAuthenticated ? "hidden" : ""}`} onClick={toggleDrawer(false)}>
              <div
                className="relative items-center justify-center rounded-full bg-[#4A4A4C] 800px:mr-0 mr-[15px]"
              >
                <BiSolidMessage color="#FFFB00" size="27" className="m-[8px]" />
                {unreadCount > 0 && (<span class="absolute bottom-0 right-0 w-4 h-4 bg-[#FF0909] rounded-full"></span>)}

              </div>
            </Link>
            <div className="relative cursor-pointer mr-[15px] hidden 800px:block">
              {isAuthenticated ? (
                <Link to={`/profile/${user?._id}/posts`}>
                  <img
                    src={`https://res.cloudinary.com/dljapfuhk/image/upload/ar_1.0,c_thumb,g_auto,f_auto/r_max/v1703309315/${user.avatar?.public_id}.jpg`}
                    className="h-[55px] w-[55px] rounded-full"
                    alt=""
                    hidden={!user}
                    onClick={toggleDrawer(false)}
                  />
                </Link>
              ) : (
                loading ? (
                  <></>
                ) : (
                  <>
                    <Link
                      to="/login"
                      onClick={toggleDrawer(false)}
                      className="mr-2 py-2 px-4 border border-solid border-2 border-black dark:border-white dark:text-white text-xl rounded-full font-bold" >
                      Login
                    </Link>
                    <Link
                      to="/sign-up"
                      className=" py-2 px-6 border border-solid border-2 border-[#FFFB00] text-xl rounded-full font-bold bg-[#FFFB00] text-black"
                      onClick={toggleDrawer(false)}>
                      Join
                    </Link>
                  </>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <Backdrop open={open || searchVisible} onClick={toggleDrawer(false)} style={{ zIndex: 10  }} invisible={true} />
      <div className={`800px:left-0 right-0 ml-2 duration-200 fixed w-[250px] bg-[#4A4A4C] text-white rounded-lg z-10 overflow-y-noscroll ${open ? "top-[70px]" : "top-[-110%]"} ${isAuthenticated ? "mr-6": "mr-2" }`}>
        {DrawerList}
      </div>

    </>
  );
}

export default Header;
