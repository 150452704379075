import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { server } from "../../server";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import { loadUser } from "../../redux/actions/user";
import { useDispatch } from "react-redux";
import { BiBadgeCheck } from "react-icons/bi";


const CompactProfile = ({ userId, showFollowButton = true, userObject = false, notificationText = "", customButton, notificationDateDiff }) => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userData, setUserData] = useState(userObject);

  useEffect(() => {
    if (userObject) {
      return
    }
    const controller = new AbortController();
    try {
      axios.get(`${server}/user/user-info/${userId}`, { signal: controller.signal }).then((response,) => {
        setUserData(response.data.user);
      });
    } catch (err) {
      console.log("error", err);
    }
    // Cleanup function
    return () => {
      controller.abort();

    };
  }, [userId, userObject]);

  const handleFollow = async () => {
    if (!user) {
      navigate('/login');
    } else {
      try {
        // Retrieve the token from the cookies
        const token = Cookies.get('token');
        // Set up the authorization header with the token
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        };
        await axios.put(`${server}/user/follow-unfollow/${userData?._id}`, {}, config);
        // no longer needed as we no longer displaying follower count
        // the follow was defined to be the return value of the axios put above
        // if (follow.data.user.following.includes(userData?._id)) {
        //   setUserData({ ...userData, followerCount: userData.followerCount + 1, followers: [...userData.followers, user._id] });
        // } else {
        //   setUserData({ ...userData, followerCount: userData.followerCount - 1, followers: userData.followers.filter((follower) => follower !== user._id) });
        // }
        dispatch(loadUser());
      } catch (err) {
        console.log("error", err);
      }
    }
  };

  return (
    <div>
      <div className="flex space-x-2 justify-between items-center dark:text-white">
        <Link to={`/profile/${userData?._id}/posts`} style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={`https://res.cloudinary.com/dljapfuhk/image/upload/ar_1.0,c_thumb,g_auto,f_auto/r_max/v1703309315/${userData?.avatar?.public_id}.jpg`}
            className="w-[70px] h-[70px] rounded-full mr-[10px]" alt="" hidden={!userData} />

          <h3 className="flex justify-start flex-col">
            <span className="font-bold flex items-center">{userData?.name} {userData?.isPremium ? (<BiBadgeCheck className="ml-1 text-black dark:text-[#FFFB00]" size="20" />) : ""} </span>
            <span className="text-black mt-[-6px] text-sm dark:text-[#FFFB00]">{notificationText || userData?.title}</span>
          </h3>

          {notificationDateDiff && (
            <>
              <span className="mx-2 text-sm">
                &#x2022;
              </span>
              <p className=" text-sm 800px:text-base"> {notificationDateDiff}</p>
            </>
          )}

        </Link>


        {user?._id === userData?._id && showFollowButton ? (
          <button
            className="w-[110px] h-[40px] flex justify-center items-center py-2 px-1 border border-transparent text-lg rounded-full text-black bg-[#FFFB00]"

            onClick={() => navigate('/profile')}>
            My Account
          </button>
        ) : (showFollowButton) ?
          user?.following?.includes(userData?._id) ? (
            <button
              className="w-[110px] h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-lg rounded-full text-black bg-[#FFFB00]"

              onClick={handleFollow}>
              Unfollow
            </button>
          ) : (
            <button
              className="w-[110px] h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-lg rounded-full text-black bg-[#FFFB00]"

              onClick={handleFollow}>
              Follow
            </button>
          ) : (customButton ? <button
            className="whitespace-nowrap w-[110px] h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-base rounded-full text-black bg-[#FFFB00]"

            onClick={() => navigate(customButton['link'])}>
            {customButton['text']}
          </button> : null)}
      </div>
    </div>
  );
};

export default CompactProfile;
