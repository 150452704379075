import React from "react";
import ExploreTweets from "../../components/Social/ExplorePosts";
import RightSidebar from "../../components/Social/RightSideBar";
import LoginPage from "../Login";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";

const Premium = () => {
  const { user } = useSelector((state) => state.user);

  return (
    <>
    <div
      className={` h-full min-h-[100svh] bg-no-repeat top-[-60px] ${styles.noramlFlex}`}
      style={{
        background: "#FDF8EE",
        // background: "radial-gradient(circle, rgba(0,0,0,1) 55%, rgba(232,52,116,1) 100%)"
      }}
    >

      <div className={`${styles.section} 800px:w-[60%] text-center`}>

        <h1
          className={`text-[45px] leading-[1.0] 800px:text-[70px] text-black font-[600] `}
        >
       Premium
        </h1>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400]  800px:w-1/2 w-full mx-auto">
        ConnectSiimply Premium offers you the opportunity to express your solidarity with our community, subscribing will prominently display a beautiful pink checkmark adjacent to your name across the entire platform.
        </p>
        <div className="w-7/12 800px:w-1/2 mx-auto pt-5 rounded-lg">
    <stripe-pricing-table pricing-table-id="prctbl_1OZRjAFQYzEzeSTh6wQcop4r"
publishable-key="pk_live_51OXcGFFQYzEzeSThbFwguaAhFylwhSBQqpwhqqqmcZoWkElBSDCxHbDbj3IvkyWTuiOy9veWpO10fv7vjUyqWq9600zCWxRde1"
client-reference-id={user?._id}
customer-email={user?.email}
className="rounded-lg">
</stripe-pricing-table>
      </div>
      </div>
    </div>

    </>
  );
};

export default Premium;
