import React, { useState, useEffect, useCallback, memo } from "react";
import { useOutletContext } from "react-router";

import CompactProfile from "../../components/Social/CompactProfile";

const FollowerList = () => {
    const [userProfile, postId, setPostId] = useOutletContext();


    return (
        <div className="min-h-[50vh]">
            {userProfile?.followerCount == 0 ? (
                <div className="text-center bg-white rounded-full py-4 mb-[50vh]">
                    This user has no followers.
                </div>) :

                <div className="mx-auto max-w-[600px] bg-white shadow rounded-lg mt-1 dark:bg-[#262627]">

                    {
                        userProfile?.followers.map((userId) => {
                            return (
                                <div className="p-2 forum-post" key={userId}>
                                    <CompactProfile userId={userId} />
                                </div>
                            );
                        })

                    }

                </div>
            }

        </div>
    );
};

export default FollowerList;
