import React, { useState } from "react";
import ExploreTweets from "../../components/Social/ExplorePosts";
import RightSidebar from "../../components/Social/RightSideBar";
import LoginPage from "../Login";
import { useSelector } from "react-redux";
import LeftSidebar from "../../components/Social/LeftSideBar";

const Explore = () => {
  const [postId, setPostId] = useState(null);
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const [posts, setPosts] = useState(null);
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(true);

  if (!isAuthenticated) {
    return <LoginPage />
  }
  return (
    <>

      <>
        <div className="py-6 grid grid-cols-1 1300px:grid-cols-10 800px:grid-cols-8">
          <div> </div>
          <div className="col-span-2 1300px:block hidden">
            <LeftSidebar leftSidebarOpen={leftSidebarOpen} setLeftSidebarOpen={setLeftSidebarOpen} />
          </div>
          <div className="col-span-4 border-t-slate-800 800px:px-6 min-h-[100vh]">
            <ExploreTweets setPostId={setPostId} postId={postId} posts={posts} setPosts={setPosts} />
          </div>
          <div className="col-span-2 ">
            <RightSidebar postId={postId} setPostId={setPostId} posts={posts} setPosts={setPosts} />
          </div>
        </div>
      </>

    </>
  );
};

export default Explore;
