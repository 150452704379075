import React from "react";
import LoginPage from "../Login";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Button } from "@material-ui/core";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../server";
import Cookies from 'js-cookie';
import { MdTitle } from "react-icons/md";
import OfferingDeletionModal from "../../components/Offerings/OfferingDeletionModal";
import ServiceUploadWidget from "../../components/Services/ServiceUploadWidget";
import { TextareaAutosize } from "@material-ui/core";
import Select, { components, MultiValueGenericProps } from 'react-select';
import makeAnimated from 'react-select/animated';
import Tooltip from '@atlaskit/tooltip';
import { FaArrowLeft } from "react-icons/fa6";
import { useLoaderData } from "react-router-dom";
import ProfileSideBar from "../../components/Profile/ProfileSidebar";

const MultiValue = (props) => {
    return (
        <Tooltip content={props.data.toolTip}>
            <components.MultiValue {...props} />
        </Tooltip>
    );
};

const animatedComponents = makeAnimated({ MultiValue: MultiValue });

const ServiceCRUD = () => {
    const { user, isAuthenticated } = useSelector((state) => state.user);
    const { serviceId } = useParams();
    const [title, setTitle] = useState("");
    const navigate = useNavigate();
    const [startingPrice, setStartingPrice] = useState(0);
    const [media, setMedia] = useState([]);
    const [languagesSpoken, setLanguagesSpoken] = useState("");
    const [description, setDescription] = useState("");
    const [selectedTags, setSelectedTags] = useState([]);
    const [skillTag, setSkillTag] = useState(null);
    const [allTags, setAllTags] = useState(null);
    const [openToCollaboration, setOpenToCollaboration] = useState(false);
    const [published, setPublished] = useState(false);
    const loaderData = useLoaderData();

    useEffect(() => {
        if (serviceId !== "new") {
            const serviceOffering = loaderData.serviceOffering;
            setTitle(serviceOffering.title);
            setStartingPrice(serviceOffering.startingPrice);
            setMedia(serviceOffering?.media);
            setDescription(serviceOffering?.description);
            setOpenToCollaboration(serviceOffering?.openToCollaboration);
            setLanguagesSpoken(serviceOffering?.languagesSpoken);
            setPublished(serviceOffering?.published);
            setSelectedTags(serviceOffering.tags.map((tag) => {
                return { value: tag._id, label: tag.name, toolTip: tag.description };
            }));
            const skillTag = serviceOffering.tags.find(tag => tag?._id === serviceOffering?.skillTag);
            if (skillTag) {
                setSkillTag({ value: skillTag?._id, label: skillTag?.name, toolTip: skillTag?.description });
            }
        }
        axios.get(`${server}/services/tags`).then((res) => {
            let tags = [];
            for (var i = 0; i < res.data.tags.length; i++) {
                tags.push({ value: res.data.tags[i]._id, label: res.data.tags[i].name, toolTip: res.data.tags[i].description });
            }
            setAllTags(tags);
        })
    }, []);

    const updateSelectedTags = (tags) => {
        setSelectedTags(tags);
        // remove the skill tag if it is not in the selected tags
        if (skillTag && !tags.find((tag) => tag.value === skillTag.value)) {
            setSkillTag("");
        }
    };
    const handleSubmit = async (e) => {

        e.preventDefault();
        try {
            if (!user) return toast.error("Please login!");
            if (!title) return toast.error("A title is required!");
            if (selectedTags.length === 0) return toast.error("Please select tags!");
            if (!skillTag) return toast.error("Please select a skill title!");
            const token = Cookies.get('token');
            // Set up the authorization header with the token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const selectedTagsArray = selectedTags.map((tag) => tag.value);

            await axios.put(`${server}/services/offering/${serviceId}`, {
                description: description,
                title: title,
                tags: selectedTagsArray,
                skillTag: skillTag.value,
                startingPrice: startingPrice,
                media: media,
                languagesSpoken: languagesSpoken,
                openToCollaboration: openToCollaboration,
                published: published

            }, config);
            toast.success("Services updated successfully!");
            navigate(`/manage-services/`);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="w-[90%] mx-auto 800px:flex py-10">
            <div>
                <ProfileSideBar active={`manage-services`} />
            </div>

            <div className="w-full py-4 800px:mx-4 shadow rounded-lg px-4  my-4 800px:my-0">
                <div className="flex justify-between mx-2 mt-2 mb-5 ">
                    <Link to={`/manage-services/`} className="text-black dark:text-[#FFFB00] flex items-center"><FaArrowLeft className="pr-1 text-black dark:text-[#FFFB00]" /> Back to My Services</Link>
                    {(serviceId !== "new") && (
                        <OfferingDeletionModal title={title} id={serviceId} type="services" />
                    )}
                </div>
                {serviceId === "new" ? (
                    <p className="text-center text-2xl mb-5 dark:text-[#d9d9d9]">Creating a new service listing</p>
                ) : (

                    <p className="text-center text-2xl mb-5 dark:text-[#d9d9d9]">Editing an existing service listing</p>
                )}

                <div>
                    <label className="pb-2 dark:text-[#FFFB00]">
                        Tags <span className="text-red-500">*</span>
                    </label>
                    <br />
                    <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        options={allTags}
                        value={selectedTags}
                        isClearable={false}
                        onChange={updateSelectedTags}
                        // defaultValue={selectedTags}
                        placeholder={"Choose tags relevant to your listing"}
                        noOptionsMessage={() => "No tags are available"}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles
                            }),
                        }}
                        classNames={{
                            multiValue: (state) =>
                                '!bg-[#FFFB00] !rounded-full',
                            multiValueRemove: (state) =>
                                '!rounded-r-full ',
                            control: (state) =>
                                '!shadow-none !rounded-[3px]'
                        }}
                        theme={(theme) => (
                            {
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary: "#FFFB00",
                                  primary25: '#FFFECC',
                                  primary50: '#FFFECC',
                                },
                            })}
                    />
                    <br />
                    <label className="pb-2 dark:text-[#FFFB00]">
                        Skill Title <span className="text-red-500">*</span>
                    </label>
                    <br />
                    <p className="dark:text-[#FFFB00]">
                    Please select the tag that best represents your service
                    </p>
                    <Select
                        components={animatedComponents}
                        options={selectedTags}
                        value={skillTag}
                        isClearable={true}
                        onChange={setSkillTag}
                        placeholder={"Select a skill title"}
                        noOptionsMessage={() => "No tags available"}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                marginTop: '10px',
                                color:'#000'
                            }),
                            option: (baseStyles, state) => ({
                                ...baseStyles,
                                color: 'black'
                               
                            }),

                        }}
                        classNames={{
                            control: (state) =>
                                '!shadow-none !rounded-[3px]',
                        }}
                        theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary: "#FFFB00",
                                  primary25: '#FFFECC',
                                  primary50: '#FFFECC',
                                },
                        })}
                    />

                    <br />
                    <label className="pb-2 dark:text-[#FFFB00]">
                        Title <span className="text-red-500">*</span>
                    </label>
                    <input
                        type="text"
                        name="title"
                        value={title}
                        className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Enter your service title..."
                    />
                    <ServiceUploadWidget media={media} setMedia={setMedia} />
                    <br />
                    <label className="pb-2 dark:text-[#FFFB00]">
                        Starting Price <span className="text-red-500">*</span>
                    </label>
                    <input
                        type="number"
                        name="startingPrice"
                        value={startingPrice}
                        className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        onChange={(e) => setStartingPrice(e.target.value)}
                    />
                    <br />
                    <label className="pb-2 dark:text-[#FFFB00]">
                        Languages spoken
                    </label>
                    <input
                        type="text"
                        name="languagesSpoken"
                        value={languagesSpoken}
                        className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        onChange={(e) => setLanguagesSpoken(e.target.value)}
                        placeholder="English, French..."
                    />
                    <br />

                    <label className="pb-2 dark:text-[#FFFB00]">
                        Description, markdown formating is supported.
                    </label>
                    <TextareaAutosize
                        minRows={3}
                        type="text"
                        name="description"
                        value={description}
                        className="mt-2 appearance-none block w-full p-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        onChange={(e) => setDescription(e.target.value)}

                    />
                    <br />
                    <div className="flex items-center dark:text-[#FFFB00]">
                        <input
                            type="checkbox"
                            name="openToCollaboration"
                            id="openToCollaboration"
                            checked={openToCollaboration}
                            className="h-4 w-4 text-blue-600 accent-[#FFFB00] border-gray-300 rounded"
                            onChange={(e) => setOpenToCollaboration(e.target.checked)}
                        />
                        <label className="pl-2" htmlFor="openToCollaboration">
                            Open to collaboration
                        </label>
                    </div>
                    <div className="flex items-center dark:text-[#FFFB00]">
                        <input
                            type="checkbox"
                            name="published"
                            id="published"
                            checked={published}
                            className="h-4 w-4 text-blue-600 accent-[#FFFB00] border-gray-300 rounded"
                            onChange={(e) => setPublished(e.target.checked)}
                        />
                        <label className="pl-2" htmlFor="published">
                            Published
                        </label>
                    </div>
                </div>
                <button
                    onClick={handleSubmit}
                    className="mt-5 w-full 800px:max-w-[50%] mx-auto h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-xl rounded-full text-black bg-[#FFFB00]"
                >

                    {serviceId === "new" ? (
                        <>Create</>
                    ) : (
                        <>Update</>
                    )}
                </button>
            </div>


        </div>
    );
};
export default ServiceCRUD;
