import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { IoMdClose } from 'react-icons/io'; 
import { FaXTwitter } from 'react-icons/fa6'; 
import { toast } from 'react-toastify';
import { FaRegShareSquare } from "react-icons/fa";

function ShareModal({ offering }) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const copyLink = () => {
        navigator.clipboard.writeText(`https://connectsiimply.com/services/?id=${offering?._id}`);
        toast.success("Link copied successfully");
        setOpen(false)
    };
    const shareOnX = () => {
        window.open(`https://twitter.com/intent/tweet?text=Check%20out%20${offering?.account.name}%27s%20%22${offering.title}%22%20service%20on%20Munch%20Munchie%3A%20https%3A%2F%2Fconnectsiimply.com%2Fservices%2F%3Fid%3D${offering?._id}`, '_blank', 'noopener,noreferrer');
        toast.success("X opened successfully");
        setOpen(false)
    };

    return (
        <div className='absolute top-0 right-0'>
            <FaRegShareSquare className="mr-2 my-2 cursor-pointer ml-[15px] text-black dark:text-[#FFFB00]"  size={20} onClick={handleClickOpen} />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ style: { minWidth: '300px', overflowX: "hidden" } }} 
            >
                <DialogTitle id="alert-dialog-title" style={{ paddingRight: '48px' }}>
                    Share Service
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        style={{
                            position: 'absolute',
                            right: '-5px',
                            top: '-5px',
                            color: '#000',
                        }}
                    >
                        <IoMdClose />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <p>
                            "{offering?.title}"
                        </p>
                        <br />
                        <button
                        onClick={copyLink} 
                className="w-full h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-xl rounded-full text-black bg-[#FFFB00]"
              >
                Copy Link
              </button>
                        <button
                        onClick={shareOnX} 
                className="mt-4 w-full h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-xl rounded-full text-black bg-[#FFFB00]"
              >
                Twitter / <span style={{ marginLeft: '0.2em' }}><FaXTwitter /></span>
              </button>
                    

                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default ShareModal;