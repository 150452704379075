import React, { useEffect, useState, useCallback, memo } from "react";

import axios from "axios";
import { useSelector } from "react-redux";
import Post from "./Post";
import { server } from "../../server";
import { toast } from "react-toastify";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Cookies from 'js-cookie';
import PostModal from "./PostModal";
import { makeStyles } from '@material-ui/core/styles';

const ExplorePosts = memo(({ setPostId, postId, posts, setPosts, url }) => {
  const { user } = useSelector((state) => state.user);
  const [sortOrder, setSortOrder] = useState('likes');
  const controller = new AbortController();
  const deletePost = useCallback((deletedData) => {
    setPosts(posts.filter((post) => post._id !== deletedData[0]));
    if (postId && deletedData[0] == postId[0]) {
      setPostId(null);
    }
  }, [posts, postId]);
  const prependPost = useCallback((newPost) => {
    setPosts(prevTimeLine => [newPost, ...prevTimeLine]);
  }, [posts]);

  useEffect(() => {
    setPostId(null);
    const token = Cookies.get('token');
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: controller.signal
    };
    axios.get(url || `${server}/posts/explore/${sortOrder}`, config)
      .then((response) => {
        // This will be executed only if the request is successful
        setPosts(response.data);
      })
      .catch((error) => {
        // This will be executed if the request fails
        console.log("error", error);
        // display the error message to the user in a toast
        toast.error(error);
      });
  }, [sortOrder, url]);

  const useStyles = makeStyles((theme) => ({
    button: {
      color: 'white', // Default text color for unselected
      borderColor: 'white', // Default border color for unselected
      '&:hover': {
        borderColor: 'white', // Ensure border color stays white on hover
      },
    },
  }));
  const classes = useStyles();

  return (

    <div className="mt-6 mb-2">
      {!url || url && (url.startsWith(`${server}/posts/timeline`) || url.endsWith(user?._id)) ? (
        <PostModal prependPost={prependPost} />) : (<></>)}

      {!url ?
        (<>
          <span className="pl-3 dark:text-[#d9d9d9]">Sort By:   </span>
          <ButtonGroup aria-label="outlined primary button group">
            <Button
              color={sortOrder === 'likes' ? 'primary' : ''}
              variant={sortOrder === 'likes' ? 'contained' : 'outlined'}
              onClick={() => { setSortOrder('likes'); controller.abort() }}
              className={sortOrder !== 'likes' && document.documentElement.classList.contains('dark') ? classes.button : ""}
            >
              Likes
            </Button>
            <Button
              color={sortOrder === 'createdAt' ? 'primary' : ''}
              variant={sortOrder === 'createdAt' ? 'contained' : 'outlined'}
              onClick={() => { setSortOrder('createdAt'); controller.abort() }}
              className={sortOrder !== 'createdAt' && document.documentElement.classList.contains('dark') ? classes.button : ""}

            >
              Date Posted
            </Button>
          </ButtonGroup></>) : (<></>)}
      {posts &&
        posts.map((post) => {
          return (
            <div key={post._id} className="forum-post">
              <Post post={post} deletePost={deletePost} setPostId={setPostId} postId={postId} prependPost={prependPost} />
            </div>
          );
        })}
    </div>
  );
});

export default ExplorePosts;