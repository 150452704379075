import React from "react";
import LoginPage from "../Login";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Button } from "@material-ui/core";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../server";
import Cookies from 'js-cookie';
import { MdTitle } from "react-icons/md";
import OfferingDeletionModal from "../../components/Offerings/OfferingDeletionModal";
import ServiceUploadWidget from "../../components/Services/ServiceUploadWidget";
import { TextareaAutosize } from "@material-ui/core";
import { FaArrowLeft } from "react-icons/fa6";
import ProfileSideBar from "../../components/Profile/ProfileSidebar";

const PortfolioCRUD = () => {
    const { user, isAuthenticated } = useSelector((state) => state.user);
    const { portfolioId } = useParams();
    const [title, setTitle] = useState("");
    const navigate = useNavigate();
    const [media, setMedia] = useState([]);
    const [description, setDescription] = useState("");
    useEffect(() => {

        if (portfolioId !== "new") {
            // Retrieve the token from the cookies
            const token = Cookies.get('token');
            // Set up the authorization header with the token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            axios.get(`${server}/portfolio/offering/${portfolioId}`, config).then((res) => {
                setTitle(res.data.portfolio.title);
                setMedia(res.data.portfolio?.media);
                setDescription(res.data.portfolio?.description);
            })
        }
    }, []);

    const handleSubmit = async (e) => {

        e.preventDefault();
        try {
            if (!user) return toast.error("Please login!");
            if (!title) return toast.error("A title is required!");
            const token = Cookies.get('token');
            // Set up the authorization header with the token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const submit = await axios.put(`${server}/portfolio/offering/${portfolioId}`, {
                description: description,
                title: title,
                media: media,
            }, config);
            toast.success("Portfolio updated successfully!");
            navigate(`/manage-portfolio/`);
        } catch (err) {
            console.log(err);
        }
    };

    return (

        <>
            <div className="w-[90%] mx-auto 800px:flex py-10">
                <div>
                    <ProfileSideBar active={`manage-services`} />
                </div>
                <div className="w-full py-4 800px:mx-4 shadow rounded-lg px-4 my-4 800px:my-0">
                    <div className="flex justify-between mx-2 mt-2 mb-5 ">
                        <Link to={`/manage-portfolio/`} className="text-black dark:text-[#FFFB00] flex items-center"><FaArrowLeft className="pr-1 text-black dark:text-[#FFFB00]" /> Back to My Portfolios</Link>
                        {(portfolioId !== "new") && (
                            <OfferingDeletionModal title={title} id={portfolioId} type="portfolio" />
                        )}
                    </div>
                    {portfolioId === "new" ? (
                        <p className="text-center text-2xl mb-5 dark:text-[#d9d9d9]">Creating a new portfolio</p>
                    ) : (

                        <p className="text-center text-2xl mb-5 dark:text-[#d9d9d9]">Editing an existing portfolio</p>
                    )}
                    <div className="flex justify-between items-center">


                    </div>

                    <div>


                        <br />
                        <label className="pb-2 dark:text-[#FFFB00]">
                            Title <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="text"
                            name="title"
                            value={title}
                            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Enter your portfolio title..."
                        />
                        <ServiceUploadWidget media={media} setMedia={setMedia} />
                        <br />
                        <label className="pb-2 dark:text-[#FFFB00]">
                            Description, markdown formating is supported.
                        </label>
                        <TextareaAutosize
                            minRows={3}
                            type="text"
                            name="description"
                            value={description}
                            className="mt-2 appearance-none block w-full p-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            onChange={(e) => setDescription(e.target.value)}

                        />
                    </div>

                    <button
                        id="profile-update-submit-button"
                        onClick={handleSubmit}
                        className="mt-5 w-full 800px:max-w-[50%] mx-auto h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-xl rounded-full text-black bg-[#FFFB00]"
                    >

                        {portfolioId === "new" ? (
                            <>Create</>
                        ) : (
                            <>Update</>
                        )}
                    </button>
                </div>
            </div>
        </>
    );
};
export default PortfolioCRUD;
