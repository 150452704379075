import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import styles from "../../styles/styles";
import Ratings from "./../Reviews/Ratings.jsx";
import { BiBadgeCheck } from "react-icons/bi";
import Tooltip from '@atlaskit/tooltip';
import Markdown from "react-markdown";
import Button from '@material-ui/core/Button';
import { useRef } from "react";
import { AiFillStar } from "react-icons/ai";
import { CiLocationOn } from "react-icons/ci";


const ServiceOfferingCard = ({ offering, urlParams,currentUrlTags, preventScrollReset = false, openAdView }) => {
    const location = useLocation();
    const windowWidth = useRef(window.innerWidth);
    const mediaContainerRef = useRef(null);
    const navigate = useNavigate();
    let newUrlParams= new URLSearchParams(urlParams);
    newUrlParams.delete("id");
    newUrlParams.delete("source");
    const newUrlParamsString= newUrlParams.toString().length>0 ? "&"+newUrlParams.toString() : "";
    
    useEffect(() => {
        let galleryWidget = null;
        if (window && !galleryWidget && offering?.media.length > 0) {
            offering?.media.forEach(item => {
                item.transformation = { "crop": "fill", "background": "#fff", "fetch_format": item.mediaType === "image" ? "webp" : "mp4" };
            });
            const loadingMedia = [{ publicId: "forum_media/1x1-00000000_dznnj4", transformation: { "crop": "fill", "background": "#fff", "dpr": "1.0", "fetch_format": "webp" }, thumbnailTransformation: { "crop": "fill", "dpr": "1.0", "fetch_format": "auto", "radius": 7 } }]
            galleryWidget = window.cloudinary.galleryWidget({
                container: mediaContainerRef.current,
                cloudName: 'dljapfuhk',
                mediaAssets: offering?.media ?? loadingMedia,
                carouselLocation: 'bottom',
                carouselStyle: 'none',
                preLoad: ["image", "video"],
                zoom: false,
                placeholderImage: false,
                analytics: false,

                zoomProps: {
                    type: "popup",
                    steps: 0,
                },
                videoProps: {
                    controls: "all",
                    autoplay: false,
                    sound: true,
                },
                aspectRatio: "4:3",
            })
        }
        if (mediaContainerRef.current && galleryWidget && offering?.media.length > 0) {
            galleryWidget.render();
        }

        return () => {
            if (galleryWidget?.destroy) {
                galleryWidget.destroy();
            }
        };
    }, [offering]);
    return (
        <div className={`flex flex-col w-full 800px:rounded-lg p-2 cursor-pointer relative max-w-[600px] mx-auto`}>
            <Link onClick={openAdView} to={`/services/${currentUrlTags ?? ""}?id=${offering._id}${newUrlParamsString}${!urlParams ? "&source=search" : ""}`}
               
                state={{ from: location.pathname, search: location.search }} preventScrollReset={preventScrollReset}>
                <h2 className=" text-lg font-medium p-1 text-black ml-2 dark:text-[#FFFB00]">
                    {offering?.skillTag?.name}
                </h2>
            </Link>
            <div className="flex 800px:flex-row flex-col">
                <div ref={mediaContainerRef} style={{ flex: 1, display: offering?.media.length == 0 ? "none" : "flex" }} className="800px:mr-4 serviceCardMediaContainer"></div>
                <Link onClick={openAdView} style={{ flex: 2 }}  to={`/services/${currentUrlTags ?? ""}?id=${offering._id}${newUrlParamsString}${!urlParams ? "&source=search" : ""}`} state={{ from: location.pathname, search: location.search }} preventScrollReset={preventScrollReset}>
                    <div className="position-relative w-full">
                        <div className="flex items-center justify-between">
                            <span className="flex flex-row items-center">
                                <div className="relative">
                                    <img
                                        src={`https://res.cloudinary.com/dljapfuhk/image/upload/ar_1.0,c_thumb,g_auto,f_auto/r_max/v1703309315/${offering.account.avatar?.public_id}.jpg`}
                                        className="w-[45px] min-w-[45px] rounded-full mr-[10px]" alt=""
                                    />
                                    {offering?.account?.online && (<div className="absolute bg-green-500 w-3 h-3 rounded-full mr-2 top-0 right-0"></div>)}
                                </div>
                                <div className="flex flex-col">
                                    <span className="items-center 800px:hidden flex dark:text-white">
                                        {offering.averageRating > 0 && (
                                            <>
                                                <AiFillStar
                                                    size={20}
                                                    color="#FFFB00"
                                                />
                                                <span className="px-2">{offering.averageRating?.toFixed(1)}</span>
                                            </>
                                        )}
                                        {offering?.account?.publicLocation ? (<><CiLocationOn className="text-black dark:text-white"/> {offering?.account.publicLocation}</>) : ' '}
                                    </span>
                                    <div className="flex items-center text-left">
                                        <h5 className={`text-xl font-[500] dark:text-white`}>{offering.account.name ?? "Loading..."}</h5>
                                        {offering.account.isPremium && (<BiBadgeCheck className="text-black dark:text-[#FFFB00]" size="20" />)}
                                    </div>
                                    {(offering.account.publicLocation || offering.averageRating > 0) ?
                                        <div className="800px:flex hidden flex-row items-center text-sm text-black dark:text-white font-medium my-[-5px] text-left">
                                            {offering.account.publicLocation ?
                                                (<>
                                                    <CiLocationOn className="dark:text-white text-black" size={15} />   {offering.account.publicLocation || ' '}
                                                </>) : ' '}
                                            {offering.averageRating > 0 && (
                                                <span className="flex flex-row items-center ml-2">
                                                    <Ratings rating={offering.averageRating ?? "0.0"} />
                                                    <span className="pl-2">{offering.averageRating?.toFixed(1)}</span>
                                                </span>)}
                                        </div>
                                        : ''}
                                    {offering.account.title ?
                                        (<div className="800px:hidden flex text-sm text-black font-medium my-[-5px] text-left">
                                            {offering.account.title || ' '}
                                        </div>) : ' '}
                                </div>
                            </span>
                        </div>
                        <div className="flex-none ml-2 mb-3">
                            <Markdown className="text-gray-700 dark:prose-invert dark:text-white prose my-2 max-w-full line-clamp-3" disallowedElements={["a"]}>
                                {offering.description}
                            </Markdown>
                        </div>
                        <div className="my-3 flex flex-wrap gap-y-2">
                            {offering.tags.slice(0, 3).map((tag, index) => (
                                <Tooltip key={index} content={tag.description}>
                                    <span className="inline-block rounded-full px-3 py-1 text-sm  mr-2 bg-[#FFFB00] ">{tag.name}</span>
                                </Tooltip>
                            ))}
                            {offering.tags.length > 3 && (
                                <span className="inline-block rounded-full px-3 py-1 text-sm  mr-2 bg-[#FFFB00] text-black">
                                    +{offering.tags.length - 3}
                                </span>
                            )}
                        </div>
                    </div>
                </Link>
                <Button
                    variant="contained"
                    color="primary"
                    className="!mx-auto !block !my-4 w-[90%] !rounded-full 800px:!hidden"
                    onClick={() => navigate(`/chat?with=${offering?.account?._id}`)}
                >
                    Message to start project
                </Button>
            </div>
        </div>
    );
};

export default ServiceOfferingCard;