import React from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { server } from '../../server';
import Cookies from 'js-cookie';
import styles from '../../styles/styles';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import Ratings from './Ratings';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { memo } from 'react';
import ReviewDeletionModal from './ReviewDeletionModal';
import Button from '@material-ui/core/Button';
import formatDistance from "date-fns/formatDistance";
import { BiBadgeCheck } from "react-icons/bi";
const OfferingReviews = ({ serviceOfferingId = false, type }) => {
    const { user, isAuthenticated } = useSelector((state) => state.user);
    const [reviewText, setReviewText] = useState("");
    const [rating, setRating] = useState(1);
    const [reviews, setReviews] = useState();
    const [showReviewEditor, setShowReviewEditor] = useState(true);
    const [reviewId, setReviewId] = useState(false);
    const [updatedAt, setUpdatedAt] = useState(new Date());
    const [averageRating, setAverageRating] = useState(0);
    const [offeringId, setOfferingId] = useState("");
    useEffect(() => {
        let offerId = ""
        offerId = serviceOfferingId
        setOfferingId(offerId);
        const token = Cookies.get('token');
        // Set up the authorization header with the token
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };
        // retrieve all existing reviews
        axios.get(`${server}/${type}/reviews/${offerId}`, config).then((res) => {
            setReviews(res.data.serviceReviews) 
            setAverageRating(res.data.averageRating);
            if (res.data.userReview) {
                setShowReviewEditor(false);
                setReviewText(res.data.userReview.review);
                setRating(res.data.userReview.rating);
                setReviewId(res.data.userReview._id);
                setUpdatedAt(new Date(res.data.userReview.updatedAt));
            }

        });
    }, [serviceOfferingId, type]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!user) return toast.error("Please login to post a review!");
        if (!reviewText) return toast.error("Please write something!");
        try {
            const token = Cookies.get('token');
            // Set up the authorization header with the token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };

            const submitPost = await axios.put(`${server}/${type}/review/${offeringId}`, {
                review: reviewText,
                rating: rating,
            }, config).then((res) => {
                setAverageRating(res.data.averageRating);
                setReviewId(res.data.reviewId);
                setUpdatedAt(new Date());
            });
            setShowReviewEditor(false);
            //   setReloadTimeline(Date.now();
            //   setPostText("");

            toast.success("Review submitted successfully!");
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <div className="p-2 dark:text-white">
            {isAuthenticated && (
                <h2 className="text-lg py-1 mb-2 bg-[#FFFB00] text-black rounded-full text-center inline-block px-4">{reviewId ? "Your Review" : "Write a Review"}</h2>           
            )}

            <div className="pb-2">
                {isAuthenticated && (
                    <>
                        <div className="flex pl-2 my-2">
                            <div className="flex" style={{ alignItems: 'center' }}>
                                <img
                                    src={`https://res.cloudinary.com/dljapfuhk/image/upload/ar_1.0,c_thumb,g_auto,f_auto/r_max/v1703309315/${user?.avatar?.public_id}.jpg`}
                                    className="w-[45px] min-w-[45px] rounded-full mr-[10px]" alt="" hidden={!user} />
                                <div  >
                                    <div className='flex items-center'>
                                        <div className="flex items-center">

                                            <h5 className={`${styles.shop_name}`}>{user?.name ?? "Loading..."}</h5>
                                            {user?.premiumExpirationDate > new Date().getTime() / 1000 && (<BiBadgeCheck className="ml-1 text-black dark:text-[#FFFB00]" size="20" />)}
                                        </div>
                                        {reviewId && (
                                            <div className='pl-1 text-sm'>
                                                <p> - {formatDistance(updatedAt, new Date())}</p>
                                            </div>
                                        )}
                                    </div>
                                    {/* <Ratings rating={offering.rating ?? 0} /> */}
                                    <div className="flex" >
                                        {[1, 2, 3, 4, 5].map((star) => (
                                            <div key={star}>
                                                {rating >= star ? (
                                                    <AiFillStar
                                                        onClick={() => showReviewEditor && setRating(star)}
                                                        size={20}
                                                        color="#FFFB00"
                                                        className={showReviewEditor ? "cursor-pointer" : "cursor-default"}
                                                    />
                                                ) : (
                                                    <AiOutlineStar
                                                        onClick={() => showReviewEditor && setRating(star)}
                                                        size={20}
                                                        color="#FFFB00"
                                                        className={showReviewEditor ? "cursor-pointer" : "cursor-default"}
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>

                        <form className=" " id="mainPostForum">
                            {showReviewEditor ? (
                                <textarea
                                    value={reviewText}
                                    onChange={(e) => setReviewText(e.target.value)}
                                    type="text"
                                    placeholder="Write your review here!"
                                    maxLength={280}
                                    className="bg-slate-200 rounded-lg w-full p-2 text-black"
                                ></textarea>
                            ) : (
                                <div className="pl-[55px] pb-4">
                                    {reviewText}
                                </div>)}

                            <div className="flex justify-between ">
                                <span>
                                    {reviewId && (
                                        <ReviewDeletionModal offeringId={offeringId} type={type} reviewId={reviewId} setReviewId={setReviewId} review={reviewText} setShowReviewEditor={setShowReviewEditor} setAverageRating={setAverageRating} setRating={setRating} setReviewText={setReviewText} />

                                    )}
                                </span>
                                {!showReviewEditor ? (
                                    <Button variant="contained"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowReviewEditor(true);
                                        }}
                                        color="primary"
                                        className='!rounded-full'
                                    >
                                        Edit your review
                                    </Button>

                                ) : (
                                    <Button variant="contained"
                                        color="primary"
                                        onClick={handleSubmit}
                                        className='!rounded-full'
                                    >
                                        {reviewId ? "Update your review" : "Post your review"}
                                    </Button>
                                )}
                            </div>

                        </form>
                    </>)}
            </div>
            <h2 className="text-lg py-1 mb-2 bg-[#FFFB00] text-black rounded-full text-center inline-block px-4">All reviews</h2>
            <div>
                <div className="flex justify-between items-center pb-3">
                    <div>
                        {reviewId ? (reviews?.length + 1) + " Reviews (including yours)" : (reviews?.length || "0") + " Reviews"}
                    </div>
                    <Ratings rating={averageRating} />

                </div>
            </div>
            {reviews?.map((review) => (
                <div className='py-2' key={review._id+" review"}>

                    <div className="flex pl-2" >
                        <div className="flex space-x-2 forum-post-top">
                            <Link to={`/profile/${review.userData?._id}/posts`} className="flex">
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img
                                        src={`https://res.cloudinary.com/dljapfuhk/image/upload/ar_1.0,c_thumb,g_auto,f_auto/r_max/v1703309315/${review.userData?.avatar?.public_id}.jpg`}
                                        className="w-[45px] min-w-[45px] rounded-full mr-[10px]" alt="" hidden={!review.userData} />
                                    <div >
                                        <div className="flex items-center">
                                            <div className="flex items-center">
                                                <h3 className="font-bold">{review.userData?.name}</h3>
                                                {review.userData?.isPremium && (<BiBadgeCheck className="text-black dark:text-[#FFFB00]" size="20" />)}
                                            </div>
                                            <div className='pl-1 text-sm'>
                                                <p> - {formatDistance(new Date(review.updatedAt), new Date())}</p>
                                            </div>
                                        </div>
                                        <Ratings rating={review.rating ?? 0} />
                                    </div>
                                </div>

                            </Link>
                            {/* <p> - {dateStr}</p> */}
                            {/* {user?._id === post?.userId && (<DeleteModal post={post} deletePost={deletePost} />)} */}
                            {/* <ShareModal post={post} />   */}
                        </div>

                        {/* <img src={`${review.userData?.avatar?.url}`} className="w-[35px] h-[35px] rounded-full mr-[10px]" alt="" />
                        <div  >
                            <h5 className={`${styles.shop_name}`}>{review.userData?.name ?? "Loading..."}</h5>
                            <Ratings rating={review.rating ?? 0} />
                        </div> */}

                    </div>
                    <div className="pl-[55px]">
                        {review.review}
                    </div>
                </div>
            ))}
        </div>
    )
}
export default OfferingReviews;