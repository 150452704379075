import React, { useState, useEffect, useCallback } from "react";
import RightSidebar from "../../components/Social/RightSideBar";
import { useSelector } from "react-redux";
import axios from "axios";
import { server } from "../../server";
import { useParams } from "react-router-dom";
import Post from "../../components/Social/Post";
import { useNavigate } from "react-router-dom";
import { AiOutlineHeart } from "react-icons/ai";
import { AiOutlineMessage } from "react-icons/ai";
import CommentList from "../../components/Social/CommentList";
import LoginPage from "../Login";

const PostView = () => {
  const navigate = useNavigate();
   const { user, isAuthenticated } = useSelector((state) => state.user);
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const deletePost = useCallback((postId) => {
    navigate(`/profile/${user?._id}/posts`)
  }, [user?._id, navigate]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const post = await axios.get(`${server}/posts/individual/${id}`);
        setPost(post.data);
      } catch (err) {
        console.log("error", err);
      }
    };
    fetchData();
  }, [id]);
  if(!isAuthenticated){
    return <LoginPage />
  } 
  return (
    <>
      <div className="py-6 grid grid-cols-1 md:grid-cols-4">
    <div></div>
        <div className="col-span-2 border-t-slate-800 800px:px-6 min-h-[100vh]">
          <div className="mt-6">
            {
              // Check if post is null before accessing properties
              post && post._id ? (
                <div key={post._id} className="forum-post">
                  <Post post={post} deletePost={deletePost} />
                </div>
              ) : <div>
                <div className="flex space-x-2 forum-post-top">
                  <img src="https://res.cloudinary.com/dljapfuhk/image/upload/v1703376348/avatars/ea9gyqgdzmtnfr2cwe9t.jpg" className="w-[45px] min-w-[45px] rounded-full mr-[10px]" alt="" />
                  <h3 className="font-bold">Loading...</h3>
                  <p> - ...</p>

                </div>
                <p style={{ wordWrap: "break-word", marginLeft: "45px" }}>Loading...</p>

                <div className="forum-post-bottom pt-2">
                  <button style={{ display: "flex", alignItems: "center" }}>
                    <AiOutlineHeart className="mr-2 my-2 cursor-pointer ml-[45px]"></AiOutlineHeart>

                    0
                  </button>

                  <AiOutlineMessage className="mr-2 my-2 cursor-pointer ml-[15px]" /> 0
                </div>
              </div>
            }
          </div>
          <div className=" w-5/6 mx-auto">
          <CommentList postId={[id, post?.userId]}  />
          </div>
        </div>
        <div className="px-6">
          <RightSidebar />
        </div>
      </div>
    </>
  );
};
export default PostView;
