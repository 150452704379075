import React, { useEffect, useRef, useState } from "react";

const UploadWidget = () => {
  const cloudinaryRef = useRef();
  const widgetRef = useRef();


  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;
    widgetRef.current = cloudinaryRef.current.createUploadWidget(
      {
        cloudName: "dljapfuhk",
        uploadPreset: "forum_media",
        thumbnails: ".uploaded-thumbnails", // The CSS selector of the element where the thumbnails will be displayed
        styles: {
          palette: {
            window: "#FDF8EE",
            inactiveTabIcon: "#000",
            tabIcon: "#000",
            link: "#000",
            menuIcons: "#000",
            windowBorder: "#000",
            sourceBg: "#FDF8EE",
            action: "#000",
          }
        }
      },
      (error, result) => {
      }
    );
    return () => {
      widgetRef.current.destroy({ removeThumbnails: true });
    }
  }, []);

  return (
    <>
      <div className="uploaded-thumbnails">
      </div>
      <button
        className="float-left h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-xl rounded-full text-black bg-[#FFFB00]"
      
        onClick={(e) => {
          e.preventDefault();
          widgetRef.current.open();
        }}
      >
        Upload Media
      </button>
    </>
  );
};

export default UploadWidget;