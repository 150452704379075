import React from "react";
import LoginPage from "../Login";
import { useSelector } from "react-redux";
import AdminListTags from "../../components/Admin/AdminListTags";
import { Link } from "react-router-dom";

const AdminManageTags = ({ type }) => {
  return (
    <>
      <div className="py-6 grid grid-cols-1 md:grid-cols-4">
        <div className="px-6">
          
        </div>
        <div className="col-span-2 border-t-slate-800 800px:px-6">
          <AdminListTags type={type} />
        </div>
        <div className="px-6">
          This page is only accessible to admins.
        </div>
      </div>
    </>
  );
};
export default AdminManageTags;
